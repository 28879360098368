import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { Calendar } from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";




const Sto7Dialog: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        dialogShow,
        setDialogShow,
    ] = useState<boolean>(false);

    const [
        dialogChange,
        setDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setDialogShow(false);
        formik.resetForm();
    };


    const projectId: string = props.projectId;
    const onClose = props.onClose;

    const onCloseModal = (values: any) => {
        onClose(values);
    }

    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban létrehozza a STO7 levelet?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                //values = {taskId: taskId, comment: values.coment};
                hideDialog();
                //alert(JSON.stringify(values, null, 2));
                onCloseModal(values);
            },
            reject: () => { }
        });
    }

    useEffect(() => {
        if (
            props.show === true
        ) {
            setDialogChange(props.change);
            setDialogShow(props.show);
        }
    }, [dialogChange, navigate, props.show, props.change, projectId]);

    const formik = useFormik({
        initialValues: {
            postMarkDate: "",
            letterDate: ""
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            postMarkDate: Yup.string()
                .required("Kötelező kitölteni!")
        }),
        onSubmit: (values) => {

            if (dialogShow) {

                confirmSave(values);
            }
        },
    });


    return (
        <>
            <Dialog header="STO7 létrehozása" visible={dialogShow} closable={false}
                maximizable modal style={{ width: "70vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-4">
                            <label
                                htmlFor="letterDate"
                                className="block text-900 font-medium mb-2"
                            >
                                Vevői levél dátuma
                            </label>
                            <Calendar
                                className={
                                    formik.errors.letterDate
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                showIcon
                                
                                dateFormat="yy.mm.dd"
                                id="letterDate"  {...formik.getFieldProps("letterDate")} />
                            {formik.errors.letterDate ? (
                                <small className="p-error">
                                    {formik.errors.letterDate}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-4">
                            <label
                                htmlFor="postMarkDate"
                                className="block text-900 font-medium mb-2"
                            >
                                Vevői levél postabélyegző dátuma
                            </label>
                            <Calendar
                                className={
                                    formik.errors.postMarkDate
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                showIcon
                                
                                dateFormat="yy.mm.dd"
                                id="postMarkDate"  {...formik.getFieldProps("postMarkDate")} />
                            {formik.errors.postMarkDate ? (
                                <small className="p-error">
                                    {formik.errors.postMarkDate}
                                </small>
                            ) : null}
                        </div>

                    </div>

                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default Sto7Dialog;





