import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface SalesContractSearchType {
    searchFields: {
        salesContractNo: string,
        vipNumber: string,
        name: string,
        zip: string,
        email: string,
        telephone: string
    },
    salesContractDraftList: [];
}


const initialState: SalesContractSearchType = {
    searchFields: {
        salesContractNo: "",
        vipNumber: "",
        name: "",
        zip: "",
        email: "",
        telephone: ""
    },
    salesContractDraftList: []
};

export const salesContractSearchSlice = createSlice({
    name: "UpdateSalesContractSearchAction",
    initialState: initialState,
    reducers: {
        setSearchFields: (state, action: PayloadAction<any>) => {
            state.searchFields = action.payload;
        },
        setSearchResult: (state, action: PayloadAction<any>) => {

            state.salesContractDraftList = action.payload;
        },
        cleanSalesContractSearch: (state) => {
            state.searchFields =
            {
                salesContractNo: "", vipNumber: "", name: "", zip: "",
                email: "", telephone: ""
            };
            state.salesContractDraftList = [];
            console.log("cleaning");
        },
    },
});

// Action creators are generated for each case reducer function
export const { setSearchFields } = salesContractSearchSlice.actions;
export const { setSearchResult } = salesContractSearchSlice.actions;
export const { cleanSalesContractSearch } = salesContractSearchSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default salesContractSearchSlice.reducer;
