import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { login, setAuthToken } from "../../service/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/SalesContract.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import { readSalesContract, searchSalesContract } from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import StatusCodes from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { cleanSalesContractSearch, setSearchFields, setSearchResult } from "../../state/salesContractSearchSlice";
import { generationF1ALetters, readF1ALetterGenerations } from "../../service/F1ALetterService";
import { Toast } from "primereact/toast";
import { generationWelcomeLetters, readWelcomeLetterGenerations } from "../../service/WelcomeLetterService";
import { generateV1Letter, readV1LetterGenerations } from "../../service/FinalLetterService";
import { confirmDialog } from "primereact/confirmdialog";




const V1LetterGeneration: React.FC = () => {

    let navigate = useNavigate();
    setAuthToken();
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [letterGenerationList, setLetterGenerationList] = useState([]);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const toast: any = useRef(null);

    const showInfo = (data: any) => {
        toast.current.show({ severity: 'info', summary: 'Info Message', detail: data.numberOfLetters + ' db nyilvántartásba vételi levél létrehozva', life: 3000 });
    }

    const excelDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.excelDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-excel mr-2 text-green-500"></i>
            <span className="font-medium">
                {rowData.excelFileName}
            </span>
        </a>
    }


    const wordDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.wordDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.wordFileName}
            </span>
        </a>
    }

    const csvDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.csvDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.csvFileName}
            </span>
        </a>
    }


    const refresh = () => {
        setLoading(true);
        readV1LetterGenerations().then(
            (response) => {
                setLetterGenerationList(
                    response.data.letterGenerationList
                );
                setLoading(false);

            },
            (error) => {
                console.log(error.response.status);
                setLoading(false);
            }
        );
    }


    useEffect(() => {
        refresh();
    }, []);


    const confirmSave = () => {
        confirmDialog({
            message: 'Valóban létrehozza a V1 leveleket?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                setBlockedPage(true);

                generateV1Letter().then(
                    (response: any) => {
                        refresh();
                        console.log(response);
                        setBlockedPage(false);
                        showInfo(response.data);
                    },
                    (error: any) => {
                        console.log(error.response);
                        setBlockedPage(false);
                        setMessageDialogHeader("Hiba");
                        setMessageDialogBody(
                            "Hiba a levelek létrehozása során!"
                        );
                        setMessageDialogError(true);
                        setMessageDialogShow(Date.now());
                    }
                );

            },
            reject: () => { }
        });
    }

    

    const formik = useFormik({
        initialValues: {
           
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            confirmSave();
        },
    });



    return (
        <>
            <BlockUI
                blocked={blockedPage}

                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        V1 levelek létrehozása folyamatban... Kérem várjon!
                    </div>
                }
            >
                <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">
                    <Toast ref={toast} />
                    <MessageDialog
                        messageDialogError={messageDialogError}
                        messageDialogShow={messageDialogShow}
                        messageDialogHeader={messageDialogHeader}
                        messageDialogBody={messageDialogBody}
                    />
                    <Panel header="V1 levelek létrehozása" className="bg-blue-50">
                        <form onSubmit={formik.handleSubmit} className="p-fluid">

                            <div className="formgrid grid">

                                <div className="col-12 md:col-6">
                                    <Button
                                        icon="pi pi-share-alt"
                                        label="Levelek létrehozása"
                                        type="submit"
                                    />
                                </div>


                            </div>
                        </form>
                    </Panel>

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="V1 vételi levél létrehozások">
                        <DataTable value={letterGenerationList} responsiveLayout="scroll" loading={loading}>
                            {/* <Column alignHeader="left" field="fromDate" header="Dátumtól" sortable></Column> */}
                            <Column alignHeader="left" field="commentDate" header="Létrehozás dátuma" sortable></Column>
                            <Column alignHeader="left" field="numberOfLetters" header="Levelek száma"></Column>
                            <Column alignHeader="left" field="excelDownloadLink" header="Excel file letöltése" body={excelDownloadLinkTemplate}></Column>
                            <Column alignHeader="left" field="wordDownloadLink" header="Word file letöltése" body={wordDownloadLinkTemplate}></Column>
                            <Column alignHeader="left" field="csvDownloadLink" header="Csv file letöltése" body={csvDownloadLinkTemplate}></Column>

                        </DataTable>
                    </Panel>

                </div>
            </BlockUI>
        </>
    );
};

export default V1LetterGeneration;
