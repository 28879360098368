import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import React from "react";
import zepterCl100LogoLightBlue from "../../asset/zepter_cl100_logo_light_blue.png";

const Footer: React.FunctionComponent<any> = (props: any) => {
    return (
        <div className="surface-section px-4 py-2 md:px-6 lg:px-8 text-center">
            <img
                src={zepterCl100LogoLightBlue}
                height="50"
                className="mt-0 mb-3"
                alt="Zepter Club Live 100 logo"
            />
            <div className="font-medium text-900 mt-0 mb-3">
                &copy; 2022 Zepter Ungarn Kft.
            </div>
            <p className="text-600 line-height-3 mt-0 mb-3">
                A Zepter  tagság teljesen ingyenes és
                kötelezettség mentes!
            </p>
            <p className="text-600 line-height-2 mt-0 mb-3">
                Csatlakozzon most!
            </p>
            <div className="flex align-items-center justify-content-center">

                <div className="footer-link mt-1 mb-0">
                    <a
                        target="_blank"
                        href="https://www.instagram.com/Zepter_Hungary/"
                        className="cursor-pointer text-700"
                        rel="noreferrer"
                    >
                        <Button
                            className="instagram p-2 mb-3"
                            aria-label="Instagram"
                        >
                            <i className="instagram pi pi-instagram px-2"></i>
                            <span className="px-3">Instagram</span>
                        </Button>
                    </a>
                    <Ripple />
                    <a
                        target="_blank"
                        href="https://www.facebook.com/ZepterHungary/"
                        className="cursor-pointer text-700"
                        rel="noreferrer"
                    >
                        <Button
                            className="facebook p-2 ml-3 mr-3 mb-3"
                            aria-label="Facebook"
                        >
                            <i className="facebook pi pi-facebook px-2"></i>
                            <span className="px-3">Facebook</span>
                        </Button>
                    </a>
                    <Ripple />
                    <a
                        target="_blank"
                        href="https://www.youtube.com/c/ZepterHungary"
                        className="cursor-pointer text-700"
                        rel="noreferrer"
                    >
                        <Button
                            className="youtube p-2 mb-3"
                            aria-label="Youtube"
                        >
                            <i className="pi pi-youtube px-2"></i>
                            <span className="px-3">Youtube</span>
                        </Button>
                    </a>
                    <Ripple />
                </div>
            </div>
            <p className="font-small mt-0 mb-3">
                verzió: {process.env.REACT_APP_VERSION}
            </p>
        </div>
    );
};

export default Footer;
