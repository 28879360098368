import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;


export const readDm22Statistics = () => {
    return axios
        .get(API_URL + '/protected/statistics/dm22')
        .then((response) => {
            return response;
        });
};

export const readDm23aStatistics = () => {
    return axios
        .get(API_URL + '/protected/statistics/dm23a')
        .then((response) => {
            return response;
        });
};

export const readDm23bStatistics = () => {
    return axios
        .get(API_URL + '/protected/statistics/dm23b')
        .then((response) => {
            return response;
        });
};


export const readF1AStatistics = (id: string) => {
    return axios
        .get(API_URL + '/protected/statistics/f1a/' + id)
        .then((response) => {
            return response;
        });
};

export const readV1Statistics = (id: string) => {
    return axios
        .get(API_URL + '/protected/statistics/v1/' + id)
        .then((response) => {
            return response;
        });
};