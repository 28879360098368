import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generationDM22Letters = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/dm22', request)
        .then((response) => {
            return response;
        });
};


export const readDM22LetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/dm22')
        .then((response) => {
            return response;
        });
};

