import React from "react";
import "../css/NotFound.css";
import zepterNotFoundImg from "../asset/zepter_cl100_404.jpg";

const NotFound: React.FC = () => {
    return (
        <>
            <h2 className="mt-0 pt-0 mb-0 pb-0 text-center">
                404 hiba – A keresett oldal nem található
            </h2>
            <img
                src={zepterNotFoundImg}
                className="mt-0 pt-0 responsive"
                alt="Zepter Club Live 100 - 404 Nem található"
            />
        </>
    );
};

export default NotFound;
