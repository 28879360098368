import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generateStonLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/ston', request)
        .then((response) => {
            return response;
        });
};


export const generateStonSLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/stons', request)
        .then((response) => {
            return response;
        });
};
