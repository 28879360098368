import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { login, setAuthToken } from "../../service/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/SalesContract.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import { readSalesContract, searchSalesContract } from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import StatusCodes from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { cleanSalesContractSearch, setSearchFields, setSearchResult } from "../../state/salesContractSearchSlice";
import {generationF1ALetters, readF1ALetterGenerations} from "../../service/F1ALetterService";
import { Toast } from "primereact/toast";
import {readDM22LetterGenerations} from "../../service/DM22LetterService";
import { generationDM23aLetters, readDM23aLetterGenerations } from "../../service/DM23aLetterService";
import { confirmDialog } from "primereact/confirmdialog";




const DM23aLetterGeneration: React.FC = () => {

    let navigate = useNavigate();
    setAuthToken();
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [letterGenerationList, setLetterGenerationList] = useState([]);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const toast: any = useRef(null);

    const showInfo = (data: any) => {
        toast.current.show({ severity: 'info', summary: 'Info Message', detail: data.numberOfLetters + ' db F1A levél létrehozva', life: 3000 });
    }

    const excelDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.excelDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-excel mr-2 text-green-500"></i>
            <span className="font-medium">
                {rowData.excelFileName}
            </span>
        </a>
    }


    const wordDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.wordDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.wordFileName}
            </span>
        </a>
    }

    const refresh = () => {
        setLoading(true);
        readDM23aLetterGenerations().then(
           
            (response) => {
                console.log(response.data.letterGenerationList);
                setLetterGenerationList(
                        response.data.letterGenerationList
                    );
                setLoading(false);
                setLoading(false);
            },
            (error) => {
                console.log(error.response.status);

            }
        );
     }


    useEffect(() => {
        refresh();
    }, []);



    const confirmSave = () => {
        confirmDialog({
            message: 'Valóban létrehozza a DM23A leveleket?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                setBlockedPage(true);

                generationDM23aLetters().then(
                    (response: any) => {
                        refresh();
                        console.log(response);
                        setBlockedPage(false);
                        showInfo(response.data);
                    },
                    (error: any) => {
                        console.log(error.response);
                        setBlockedPage(false);
                        setMessageDialogHeader("Hiba");
                        setMessageDialogBody(
                            "Hiba a levelek létrehozása során!"
                        );
                        setMessageDialogError(true);
                        setMessageDialogShow(Date.now());
                    }
                );

            },
            reject: () => { }
        });
    }


    const formik = useFormik({
        initialValues: {
            fromDate: "2022.01.01",
            toDate: "2023.02.28",
        },
        validationSchema: Yup.object({
            fromDate: Yup.string()
                .required("Kötelező kitölteni!"),
            toDate: Yup.string()
                .required("Kötelező kitölteni!"),
        }),
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
          confirmSave();  
        },
    });



    return (
        <>
            <BlockUI
                blocked={blockedPage}

                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        DM23a levelek létrehozása folyamatban... Kérem várjon!
                    </div>
                }
            >
                <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">
                    <Toast ref={toast} />
                    <MessageDialog
                        messageDialogError={messageDialogError}
                        messageDialogShow={messageDialogShow}
                        messageDialogHeader={messageDialogHeader}
                        messageDialogBody={messageDialogBody}
                    />
                    <Panel header="DM23A levelek létrehozása" className="bg-blue-50">
                        <form onSubmit={formik.handleSubmit} className="p-fluid">


                            <div className="formgrid grid">
                                <div className="field col-12 md:col-6">
                                    <label
                                        htmlFor="fromDate"
                                        className="block text-900 font-medium mb-2"
                                    >
                                        Dátumtól
                                    </label>
                                    <InputText
                                        className={
                                            formik.errors.fromDate
                                                ? "w-full p-invalid"
                                                : "w-full"
                                        }
                                        readOnly
                                        id="fromDate"  {...formik.getFieldProps("fromDate")} />


                                    {formik.errors.fromDate ? (
                                        <small className="p-error">
                                            {formik.errors.fromDate.toString()}
                                        </small>
                                    ) : null}
                                </div>

                                <div className="field col-12 md:col-6">
                                    <label
                                        htmlFor="toDate"
                                        className="block text-900 font-medium mb-2"
                                    >
                                        Dátumig
                                    </label>

                                    <InputText
                                        className={
                                            formik.errors.toDate
                                                ? "w-full p-invalid"
                                                : "w-full"
                                        }
                                        readOnly
                                        id="toDate"  {...formik.getFieldProps("toDate")} />


                                    {formik.errors.toDate ? (
                                        <small className="p-error">
                                            {formik.errors.toDate}
                                        </small>
                                    ) : null}
                                </div>


                            </div>






                            <div className="formgrid grid">

                                <div className="col-12 md:col-6">
                                    <Button

                                        icon="pi pi-share-alt"
                                        label="Levelek létrehozása"
                                        type="submit"
                                    />
                                </div>


                            </div>
                        </form>
                    </Panel>

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="DM23A levél létrehozások">
                        <DataTable value={letterGenerationList} responsiveLayout="scroll" loading={loading}>
                            <Column alignHeader="left" field="fromDate" header="Dátumtól" sortable></Column>
                            <Column alignHeader="left" field="toDate" header="Dátumig" sortable></Column>
                            <Column alignHeader="left" field="numberOfLetters" header="Levelek száma"></Column>
                            <Column alignHeader="left" field="excelDownloadLink" header="Excel file letöltése" body={excelDownloadLinkTemplate}></Column>
                            <Column alignHeader="left" field="wordDownloadLink" header="Word file letöltése" body={wordDownloadLinkTemplate}></Column>

                        </DataTable>
                    </Panel>

                </div>
            </BlockUI>
        </>
    );
};

export default DM23aLetterGeneration;
