import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generationDM23aLetters = () => {
    return axios
        .post(API_URL + '/protected/letter/dm23a')
        .then((response) => {
            return response;
        });
};


export const readDM23aLetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/dm23a')
        .then((response) => {
            return response;
        });
};

