import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { setAuthToken } from "../../service/AuthService";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/SalesContract.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import { readSalesContract } from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { useSearchParams } from "react-router-dom";
import { Ripple } from "primereact/ripple";
import { Menubar } from 'primereact/menubar';
import { StyleClass } from "primereact/styleclass";
import { Badge } from "primereact/badge";
import { Toast } from "primereact/toast";
import StovDialog from "./StovDialog";
import { generateStovLetter, generateStovSLetter } from "../../service/StovLetterService";
import F5Dialog from "./F5Dialog";
import { generateF5Letter } from "../../service/F5LetterService";
import { generateV2Letter } from "../../service/FinalLetterService";
import { confirmDialog } from "primereact/confirmdialog";
import StonDialog from "./StonDialog";
import { generateStonLetter, generateStonSLetter } from "../../service/StonLetterService";
import { generateNyi2Letter } from "../../service/NyiLetterService";
import { generateSto7Letter, generateSto7SLetter } from "../../service/Sto7LetterService";
import Sto7Dialog from "./Sto7Dialog";
import Sto7SDialog from "./Sto7SDialog";
import StonSDialog from "./StonSDialog";
import StovSDialog from "./StovSDialog";
import { generateSto5SLetter } from "../../service/Sto5LetterService";
import Sto5SDialog from "./Sto5SDialog";
import {generateStobSLetter} from "../../service/StobLetterService";
import StobSDialog from "./StobSDialog";
import {generateSto0Letter, generateSto0SLetter} from "../../service/Sto0LetterService";
import Sto0SDialog from "./Sto0SDialog";
import {generateStouLetter, generateStouSLetter} from "../../service/StouLetterService";
import StouDialog from "./StouDialog";
import StouSDialog from "./StouSDialog";
import StoiDialog from "./StoiDialog";
import StokDialog from "./StokDialog";
import Stok15Dialog from "./Stok15Dialog";
import Stok15SDialog from "./Stok15SDialog";
import StoiSDialog from "./StoiSDialog";
import StokSDialog from "./StokSDialog";
import {generateStoiLetter, generateStoiSLetter} from "../../service/StoiLetterService";
import {generateStokLetter, generateStokSLetter} from "../../service/StokLetterService";
import {generateStok15Letter, generateStok15SLetter} from "../../service/Stok15LetterService";



const SalesContract: React.FC = () => {

    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const salesContractNo: any = searchParams.get("salesContractNo");

    //setAuthToken();
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [salesContractDto, setSalesContractDto] = useState<any>(null);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const [invoiced, setInvoiced] = useState<boolean>(false);
    const [shipped, setShipped] = useState<boolean>(false);
    const [completelyInvoiced, setCompletelyInvoiced] = useState<boolean>(false);
    const [completelyShipped, setCompletelyShipped] = useState<boolean>(false);
    const [canceled, setCanceled] = useState<boolean>(false);

    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const btnRef3 = useRef(null);
    const btnRef4 = useRef(null);
    const btnRef5 = useRef(null);

    const toast: any = useRef(null);

    const [updateSto7DialogShow, setUpdateSto7DialogShow] =
        useState<boolean>(false);

    const [updateSto7DialogChange, setUpdateSto7DialogChange] =
        useState<number>(0);

    const [updateStonSDialogShow, setUpdateStonSDialogShow] =
        useState<boolean>(false);

    const [updateStonSDialogChange, setUpdateStonSDialogChange] =
        useState<number>(0);

    const [updateStovSDialogShow, setUpdateStovSDialogShow] =
        useState<boolean>(false);

    const [updateStovSDialogChange, setUpdateStovSDialogChange] =
        useState<number>(0);

    const [updateSto0SDialogShow, setUpdateSto0SDialogShow] =
        useState<boolean>(false);

    const [updateSto0SDialogChange, setUpdateSto0SDialogChange] =
        useState<number>(0);


    const [updateSto7SDialogShow, setUpdateSto7SDialogShow] =
        useState<boolean>(false);

    const [updateSto7SDialogChange, setUpdateSto7SDialogChange] =
        useState<number>(0);

    const [updateSto5SDialogShow, setUpdateSto5SDialogShow] =
        useState<boolean>(false);

    const [updateSto5SDialogChange, setUpdateSto5SDialogChange] =
        useState<number>(0);

    const [updateStobSDialogShow, setUpdateStobSDialogShow] =
        useState<boolean>(false);

    const [updateStobSDialogChange, setUpdateStobSDialogChange] =
        useState<number>(0);

    const [updateStouSDialogShow, setUpdateStouSDialogShow] =
        useState<boolean>(false);

    const [updateStouSDialogChange, setUpdateStouSDialogChange] =
        useState<number>(0);

    const [updateStoiSDialogShow, setUpdateStoiSDialogShow] =
        useState<boolean>(false);

    const [updateStoiSDialogChange, setUpdateStoiSDialogChange] =
        useState<number>(0);

    const [updateStokSDialogShow, setUpdateStokSDialogShow] =
        useState<boolean>(false);

    const [updateStokSDialogChange, setUpdateStokSDialogChange] =
        useState<number>(0);

    const [updateStok15SDialogShow, setUpdateStok15SDialogShow] =
        useState<boolean>(false);

    const [updateStok15SDialogChange, setUpdateStok15SDialogChange] =
        useState<number>(0);


    const [updateStovDialogShow, setUpdateStovDialogShow] =
        useState<boolean>(false);

    const [updateStovDialogChange, setUpdateStovDialogChange] =
        useState<number>(0);

    const [updateStonDialogShow, setUpdateStonDialogShow] =
        useState<boolean>(false);

    const [updateStonDialogChange, setUpdateStonDialogChange] =
        useState<number>(0);

    const [updateF5DialogShow, setUpdateF5DialogShow] =
        useState<boolean>(false);

    const [updateF5DialogChange, setUpdateF5DialogChange] =
        useState<number>(0);


    const [updateStouDialogShow, setUpdateStouDialogShow] =
        useState<boolean>(false);

    const [updateStouDialogChange, setUpdateStouDialogChange] =
        useState<number>(0);


    const [updateStoiDialogShow, setUpdateStoiDialogShow] =
        useState<boolean>(false);

    const [updateStoiDialogChange, setUpdateStoiDialogChange] =
        useState<number>(0);


    const [updateStokDialogShow, setUpdateStokDialogShow] =
        useState<boolean>(false);

    const [updateStokDialogChange, setUpdateStokDialogChange] =
        useState<number>(0);

    const [updateStok15DialogShow, setUpdateStok15DialogShow] =
        useState<boolean>(false);

    const [updateStok15DialogChange, setUpdateStok15DialogChange] =
        useState<number>(0);


    const showStov = () => {
        console.log("showStov is called");
        setUpdateStovDialogShow(true);
        setUpdateStovDialogChange(Date.now());
    };

    const showSto7 = () => {
        console.log("showSto7 is called");
        setUpdateSto7DialogShow(true);
        setUpdateSto7DialogChange(Date.now());
    };

    const showStonS = () => {
        console.log("showStons is called");
        setUpdateStonSDialogShow(true);
        setUpdateStonSDialogChange(Date.now());
    };

    const showStovS = () => {
        console.log("showStovs is called");
        setUpdateStovSDialogShow(true);
        setUpdateStovSDialogChange(Date.now());
    };

    const showSto0S = () => {
        console.log("showSto0s is called");
        setUpdateSto0SDialogShow(true);
        setUpdateSto0SDialogChange(Date.now());
    };

    const showSto7S = () => {
        console.log("showSto7s is called");
        setUpdateSto7SDialogShow(true);
        setUpdateSto7SDialogChange(Date.now());
    };

    const showStouS = () => {
        console.log("showStous is called");
        setUpdateStouSDialogShow(true);
        setUpdateStouSDialogChange(Date.now());
    };

    const showStoiS = () => {
        console.log("showStois is called");
        setUpdateStoiSDialogShow(true);
        setUpdateStoiSDialogChange(Date.now());
    };

    const showStokS = () => {
        console.log("showStoks is called");
        setUpdateStokSDialogShow(true);
        setUpdateStokSDialogChange(Date.now());
    };

    const showStok15S = () => {
        console.log("showStok15s is called");
        setUpdateStok15SDialogShow(true);
        setUpdateStok15SDialogChange(Date.now());
    };

    const showSton = () => {
        console.log("showSton is called");
        setUpdateStonDialogShow(true);
        setUpdateStonDialogChange(Date.now());
    };

    const showSto5S = () => {
        console.log("showSto5s is called");
        setUpdateSto5SDialogShow(true);
        setUpdateSto5SDialogChange(Date.now());
    };

    const showStobS = () => {
        console.log("showStobs is called");
        setUpdateStobSDialogShow(true);
        setUpdateStobSDialogChange(Date.now());
    };


    const showF5 = () => {
        console.log("showF5 is called");
        setUpdateF5DialogShow(true);
        setUpdateF5DialogChange(Date.now());
        //toast.current.show({ severity: 'info', summary: 'STOV', detail: 'STOV' });
    };

    const showStou = () => {
        console.log("showStou is called");
        setUpdateStouDialogShow(true);
        setUpdateStouDialogChange(Date.now());
    };

    const showStoi = () => {
        console.log("showStoi is called");
        setUpdateStoiDialogShow(true);
        setUpdateStoiDialogChange(Date.now());
    };

    const showStok = () => {
        console.log("showStok is called");
        setUpdateStokDialogShow(true);
        setUpdateStokDialogChange(Date.now());
    };

    const showStok15 = () => {
        console.log("showStok15 is called");
        setUpdateStok15DialogShow(true);
        setUpdateStok15DialogChange(Date.now());
    };


    const showSto0 = () => {
        console.log("showSto0 is called");
        confirmDialog({
            message: 'Valóban létrehozza a STO0 levelet?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                let values: any = { salesContractNo: salesContractNo};
                generateSto0Letter(values).then(
                    (response) => {
                        toast.current.show({ severity: 'success', summary: 'STO0', detail: 'STO0 sikeresen létrehozva', life: 3000 });
                        refresh();
                    },
                    (error) => {
                        console.log(error);
                        toast.current.show({
                            severity: 'error', summary: 'A STO0 nem hozható létre', detail: error.response.data, life: 5000
                        });

                    }
                );

            },
            reject: () => { }
        });
    };


    const onStovDialogClose = (data: any) => {
        console.log(data);
        let values: any = { postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo, repayable: data.repayable };
        generateStovLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOV', detail: 'STOV sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({
                    severity: 'error', summary: 'A STOV nem hozható létre', detail: error.response.data, life: 5000
                });

            }
        );
    };

    const onStovSDialogClose = (data: any) => {
        console.log(data);
        let values: any = {notes: data.notes, postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo, repayable: data.repayable };
        generateStovSLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOV_S', detail: 'STOV_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOV_S nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onSto0SDialogClose = (data: any) => {
        console.log(data);
        let values: any = { notes: data.notes, postMarkDate: data.postMarkDate, salesContractNo: salesContractNo};
        generateSto0SLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STO0_S', detail: 'STO0_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STO0_S nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStonDialogClose = (data: any) => {
        console.log(data);
        let values: any = { postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStonLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STON', detail: 'STON sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STON nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStonSDialogClose = (data: any) => {
        console.log(data);
        let values: any = { notes: data.notes, postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStonSLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STON_S', detail: 'STON_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STON_S nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };



    const onSto7DialogClose = (data: any) => {
        console.log(data);
        let values: any = { postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateSto7Letter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STO7', detail: 'STO7 sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STO7 nem hozható létre.', detail: error.response.data, life: 5000 });
            }
        );
    };

    const onSto7SDialogClose = (data: any) => {
        console.log("onSto7SDialogClose");
        let values: any = { notes: data.notes, salesContractNo: salesContractNo };
        generateSto7SLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STO7_S', detail: 'STO7_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STO7_S nem hozható létre.', detail: error.response.data, life: 5000 });
            }
        );
    };


    const onSto5SDialogClose = (data: any) => {
        console.log("onSto5SDialogClose");
        let values: any = { notes: data.notes, salesContractNo: salesContractNo };
        generateSto5SLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STO5_S', detail: 'STO5_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STO5_S nem hozható létre.', detail: error.response.data, life: 5000 });
            }
        );
    };

    const onStobSDialogClose = (data: any) => {
        console.log("onStobSDialogClose");
        let values: any = { notes: data.notes, salesContractNo: salesContractNo };
        generateStobSLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOB_S', detail: 'STOB_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOB_S nem hozható létre.', detail: error.response.data, life: 5000 });
            }
        );
    };


    const onF5DialogClose = (data: any) => {
        console.log("onF5DialogClose is called " + data);
        let values: any = { numberOfYellowChecks: data, salesContractNo: salesContractNo };
        generateF5Letter(values).then(
            (response) => {
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'F5', detail: 'Az F5 nem hozható létre.', life: 10000 });
            }
        );
    };


    const onStouDialogClose = (data: any) => {
        console.log(data);
        let values: any = { postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStouLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOU', detail: 'STOU sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOU nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStoiDialogClose = (data: any) => {
        console.log(data);
        let values: any = { postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo, repayable: data.repayable  };
        generateStoiLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOI', detail: 'STOI sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOI nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStokDialogClose = (data: any) => {
        console.log(data);
        let values: any = { letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStokLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOK', detail: 'STOK sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOK nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStok15DialogClose = (data: any) => {
        console.log(data);
        let values: any = { letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStok15Letter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOK15', detail: 'STOK15 sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOK15 nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStouSDialogClose = (data: any) => {
        console.log(data);
        let values: any = { notes: data.notes, postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStouSLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOU_S', detail: 'STOU_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOU_S nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStoiSDialogClose = (data: any) => {
        console.log(data);
        let values: any = { notes: data.notes, postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStoiSLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOI_S', detail: 'STOI_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOI_S nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStokSDialogClose = (data: any) => {
        console.log(data);
        let values: any = { notes: data.notes, postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStokSLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOK_S', detail: 'STOK_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOK_S nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const onStok15SDialogClose = (data: any) => {
        console.log(data);
        let values: any = { notes: data.notes, postMarkDate: data.postMarkDate, letterDate: data.letterDate, salesContractNo: salesContractNo };
        generateStok15SLetter(values).then(
            (response) => {
                toast.current.show({ severity: 'success', summary: 'STOK15_S', detail: 'STOK15_S sikeresen létrehozva', life: 3000 });
                refresh();
            },
            (error) => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'A STOK15_S nem hozható létre', detail: error.response.data, life: 5000 });

            }
        );
    };

    const createV2Letter = () => {
        confirmDialog({
            message: 'Valóban létrehozza a V2-es levelet?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                setBlockedPage(true);
                console.log("createV2Letter is called");
                let values: any = { numberOfYellowChecks: 1, salesContractNo: salesContractNo };
                generateV2Letter(values).then(
                    (response) => {
                        refresh();
                    },
                    (error) => {
                        console.log(error);

                    }
                );
            },
            reject: () => { }
        });
    };


    const createNyi2Letter = () => {
        confirmDialog({
            message: 'Valóban létrehozza a NYI2-es levelet?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                setBlockedPage(true);
                console.log("createV2Letter is called");
                let values: any = { salesContractNo: salesContractNo };
                generateNyi2Letter(values).then(
                    (response) => {
                        refresh();
                    },
                    (error) => {
                        console.log(error);

                    }
                );
            },
            reject: () => { }
        });
    };



    const refresh = () => {
        console.log(salesContractNo);
        setBlockedPage(true);
        setMessageDialogError(false);
        setMessageDialogShow(0);
        readSalesContract(salesContractNo).then(
            (response) => {
                console.log(response);
                setSalesContractDto(response.data);
                setBlockedPage(false);
                setInvoiced(false);
                setShipped(false);
                setCompletelyInvoiced(false);
                setCompletelyShipped(false);
                setCanceled(false);
                if (response.data.invoiced === 1) {
                    setInvoiced(true);
                }
                if (response.data.shipped === 1) {
                    setShipped(true);
                }
                if (response.data.completelyInvoiced === 1) {
                    setCompletelyInvoiced(true);
                }
                if (response.data.completelyShipped === 1) {
                    setCompletelyShipped(true);
                }
                if (response.data.canceled === 1) {
                    setCanceled(true);
                }
            },
            (error) => {
                console.log(error);
                setBlockedPage(false);
                setSalesContractDto(null);
                setMessageDialogHeader("Hiba a keresés során");
                setMessageDialogBody(
                    "A megadott szerződésszám nem található111"

                );
                setMessageDialogError(true);
                setMessageDialogShow(Date.now());
            }
        );
    }


    useEffect(() => {
        refresh();
    }, [salesContractNo]);


    const formik = useFormik({
        initialValues: {
            salesContractNo: "",
        },
        validationSchema: Yup.object({
            salesContractNo: Yup.string()
                .min(10, "Minimum 10 karakter kell, hogy legyen!")
                .required("Kötelező kitölteni!")
        }),
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            setBlockedPage(true);
            readSalesContract(values.salesContractNo).then(
                (response) => {
                    console.log(response);
                    setSalesContractDto(response.data);
                    setBlockedPage(false);
                    setInvoiced(false);
                    setShipped(false);
                    setCompletelyInvoiced(false);
                    setCompletelyShipped(false);
                    setCanceled(false);
                    if (response.data.invoiced === 1) {
                        setInvoiced(true);
                    }
                    if (response.data.shipped === 1) {
                        setShipped(true);
                    }
                    if (response.data.completelyInvoiced === 1) {
                        setCompletelyInvoiced(true);
                    }
                    if (response.data.completelyShipped === 1) {
                        setCompletelyShipped(true);
                    }
                    if (response.data.canceled === 1) {
                        setCanceled(true);
                    }
                },
                (error) => {
                    console.log(error);
                    setBlockedPage(false);
                    setSalesContractDto(null);
                    setMessageDialogHeader("Hiba a keresés során");
                    setMessageDialogBody(
                        "A megadott szerződésszám nem található222"

                    );
                    setMessageDialogError(true);
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    const formatCurrency = (value: any) => {
        return value.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 });
    }

    const formatNumber = (value: any) => {
        return value.toLocaleString('hu-HU', { style: 'decimal', maximumFractionDigits: 0 });
    }


    const beforeDiscountAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.beforeDiscountAmount);
    }

    const quantityTemplate = (rowData: any) => {
        return formatNumber(rowData.quantity);
    }

    const stockTemplate = (rowData: any) => {
        return formatNumber(rowData.stock);
    }

    const reservedStockTemplate = (rowData: any) => {
        return formatNumber(rowData.reservedStock);
    }

    const unitPriceTemplate = (rowData: any) => {
        return formatCurrency(rowData.unitPrice);
    }

    const netAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.netAmount);
    }

    const grossAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.grossAmount);
    }


    const lineAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.lineAmount);
    }

    const afterDiscountAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.afterDiscountAmount);
    }


    const warehouseHeaderTemplate = (data: any) => {
        return (
            <React.Fragment>
                <span >{data.salesItemNo}</span>
            </React.Fragment>
        );
    }

    const amountBodyTemplate = (rowData: any) => {
        return formatCurrency(rowData.amount);
    }

    const downloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.downloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.fileName}
            </span>

        </a>
    }

    const cancellationDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.downloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.letterFileName}
            </span>

        </a>
    }

    const cancellationDownloadCsvLinkTemplate = (rowData: any) => {
        if (rowData.csvFileName === null) {
            return (<div style={{ width: "45px" }}></div>);
        } else {
            return (
                <a
                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                    style={{ borderRadius: "12px", textDecoration: "none" }}
                    href={rowData.downloadCsvLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="pi pi-file mr-2 text-green-500"></i>
                </a>
            );
        }
    };



    const cancellationDownloadOriginalLinkTemplate = (rowData: any) => {
        return (
            <a
                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                style={{ borderRadius: "12px", textDecoration: "none" }}
                href={rowData.downloadOriginalLink}
                target="_blank"
                rel="noreferrer"
            >
                <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            </a>
        );
    };

    const cancellationDownloadCopyLinkTemplate = (rowData: any) => {
        return (
            <a
                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                style={{ borderRadius: "12px", textDecoration: "none" }}
                href={rowData.downloadCopyLink}
                target="_blank"
                rel="noreferrer"
            >
                <i className="pi pi-file-word mr-2 text-red-500"></i>
            </a>
        );
    };

    const calculateSumAmount = (salesContractLineList: any) => {
        let total = 0;

        if (salesContractLineList) {
            for (let line of salesContractLineList) {
                total += line.afterDiscountAmount;
            }
        }
        return formatCurrency(total);
    }


    const calculateSumOfPayment = (contractLedgerEntryList: any) => {
        let total = 0;

        if (contractLedgerEntryList) {
            for (let line of contractLedgerEntryList) {
                total += line.amount;
            }
        }
        return formatCurrency(total);
    }


    const calculateSumOfSalesInvoiceLines = (list: any, documentNo: string) => {
        let total = 0;

        if (list) {
            for (let line of list) {
                if (line.documentNo === documentNo) {
                    total += line.grossAmount;
                }
            }
        }
        return formatCurrency(total);
    }

    const calculateSumOfCreditMemoLines = (list: any, documentNo: string) => {
        let total = 0;

        if (list) {
            for (let line of list) {
                if (line.documentNo === documentNo) {
                    total += line.grossAmount;
                }
            }
        }
        return formatCurrency(total);
    }



    const salesContractLineFooterTemplate = () => {
        return (
            <React.Fragment>
                <td colSpan={5} style={{ textAlign: 'left' }}></td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Összesen</td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{calculateSumAmount(salesContractDto.salesContractLineList)}</td>
            </React.Fragment>
        );
    }


    // const contractLedgerEntryFooterTemplate = () => {
    //     return (
    //         <React.Fragment>
    //             <td colSpan={2} style={{ textAlign: 'left' }}></td>
    //             <td style={{ fontWeight: 'bold', textAlign: 'left' }}>Befizetések összértéke</td>
    //             <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{calculateSumOfPayment(salesContractDto.contractLedgerEntryList)}</td>
    //         </React.Fragment>
    //     );
    // }


    const contractLedgerEntryFooterTemplate = () => {
        let total = 0;
        if (salesContractDto.contractLedgerEntryList) {
            for (let line of salesContractDto.contractLedgerEntryList) {
                total += line.amount;
            }
        }
        let totalAmount = formatCurrency(total);
        let remainingAmount = formatCurrency(salesContractDto.contractValue - total);

        return (
            <>
                <div className="flex  justify-content-end mb-2">
                    <span >Befizetések összesen: </span>
                    <span className="flex  justify-content-end" style={{ width: "150px" }} >{totalAmount}</span>
                </div>
                <div className="flex  justify-content-end">
                    <span >Befizetendő összesen: </span>
                    <span className="flex  justify-content-end" style={{ width: "150px" }} >{remainingAmount}</span>
                </div>
            </>
        );
    }



    const salesInvoiceLineFooterTemplate = (data: any) => {
        return (
            <React.Fragment>
                <td colSpan={7} style={{ textAlign: 'left' }}></td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Összesen</td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{calculateSumOfSalesInvoiceLines(salesContractDto.salesInvoiceLineList, data.documentNo)}</td>
            </React.Fragment>
        );
    }

    const salesCreditMemoLineFooterTemplate = (data: any) => {
        return (
            <React.Fragment>
                <td colSpan={8} style={{ textAlign: 'left' }}></td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Összesen</td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{calculateSumOfCreditMemoLines(salesContractDto.salesCreditMemoLineList, data.documentNo)}</td>
            </React.Fragment>
        );
    }

    const items = [
        {
            label: 'Vissza',
            icon: 'pi pi-fw pi-arrow-left',
            command: () => { navigate(-1); }
        },
        {
            label: 'Levelek',
            icon: 'pi pi-fw pi-file',
            items: [
                {
                    label: 'Nyilvántartásba vételi',
                    icon: 'pi pi-fw pi-map-marker'
                },
                // {
                //     separator: true
                // },
                {
                    label: 'Információs',
                    icon: 'pi pi-fw pi-bolt'
                },
                {
                    label: 'Végelszámolás',
                    icon: 'pi pi-fw pi-book'
                }
            ]
        },

    ];

    const headerTemplate = (options: PanelHeaderTemplateOptions) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <>
                <div className={className}>
                    <Button className="p-button-rounded p-button-text" icon="pi pi-arrow-left" onClick={() => { navigate(-1); }} />

                    <span className={titleClassName}>
                        Szerződés alapadatai
                    </span>

                    <div className="card ml-5">

                    </div>
                </div>

            </>
        )
    }



    return (
        <>
            <Toast ref={toast}></Toast>
            <BlockUI
                blocked={blockedPage}
                fullScreen
                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        Szerződés betöltése folyamatban... Kérem várjon!
                    </div>
                }
            >
                <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">
                    <MessageDialog
                        messageDialogError={messageDialogError}
                        messageDialogShow={messageDialogShow}
                        messageDialogHeader={messageDialogHeader}
                        messageDialogBody={messageDialogBody}
                    />

                    {salesContractDto ? (
                        <>
                            <div className="surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static" style={{ minHeight: '80px' }}>
                                <StyleClass nodeRef={btnRef1} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
                                    <a ref={btnRef1} className="cursor-pointer block lg:hidden text-700">
                                        <i className="pi pi-bars text-4xl"></i>
                                        <Ripple />
                                    </a>
                                </StyleClass>
                                <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
                                    <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                        <li>
                                            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                                onClick={() => { navigate(-1); }}>
                                                <i className="pi pi-arrow-left mr-2"></i>
                                                <span>Vissza</span>
                                                <Ripple />
                                            </a>
                                        </li>

                                        <li className="lg:relative">
                                            <StyleClass nodeRef={btnRef2} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                                <a ref={btnRef2} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                                    <i className="pi pi-bars mr-2"></i>
                                                    <span>Levelek</span>
                                                    <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>
                                            <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                                                        <i className="pi pi-map-marker mr-2"></i>
                                                        <span className="font-medium">NYV</span>
                                                        <Ripple />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { showF5(); }}>
                                                        <i className="pi pi-info-circle mr-2"></i>
                                                        <span className="font-medium">F5</span>
                                                        <Ripple />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { createV2Letter(); }}>
                                                        <i className="pi pi-paperclip mr-2"></i>
                                                        <span className="font-medium">V2</span>
                                                        <Ripple />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { createNyi2Letter(); }}>
                                                        <i className="pi pi-truck mr-2"></i>
                                                        <span className="font-medium">NYI2</span>
                                                        <Ripple />
                                                    </a>
                                                </li>

                                            </ul>
                                        </li>

                                        <li className="lg:relative">
                                            <StyleClass nodeRef={btnRef3} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                                <a ref={btnRef3} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                                    <i className="pi pi-globe mr-2"></i>
                                                    <span>Vevői elállások</span>
                                                    <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>
                                            <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { showStov(); }}>
                                                        <i className="pi pi-info-circle mr-2"></i>
                                                        <span className="font-medium">STOV</span>
                                                        <Ripple />
                                                    </a>
                                                </li>


                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { showSton(); }}>
                                                        <i className="pi pi-map-marker mr-2"></i>
                                                        <span className="font-medium">STON</span>
                                                        <Ripple />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { showSto7(); }}>
                                                        <i className="pi pi-mobile mr-2"></i>
                                                        <span className="font-medium">STO7</span>
                                                        <Ripple />
                                                    </a>
                                                </li>



                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { showStou(); }}>
                                                        <i className="pi pi-paperclip mr-2"></i>
                                                        <span className="font-medium">STOU</span>
                                                        <Ripple />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full" onClick={() => { showStoi(); }}>
                                                        <i className="pi pi-history mr-2"></i>
                                                        <span className="font-medium">STOI</span>
                                                        <Ripple />
                                                    </a>
                                                </li>



                                            </ul>
                                        </li>


                                        <li className="lg:relative">
                                            <StyleClass nodeRef={btnRef4} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                                <a ref={btnRef4} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                                    <i className="pi pi-globe mr-2"></i>
                                                    <span>Zepter elállások</span>
                                                    <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>
                                            <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">


                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showSto0();
                                                       }}>
                                                        <i className="pi pi-info-circle mr-2"></i>
                                                        <span className="font-medium">STO0</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>


                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                                                        <i className="pi pi-map-marker mr-2"></i>
                                                        <span className="font-medium">STO5</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStok();
                                                       }}>
                                                        <i className="pi pi-history mr-2"></i>
                                                        <span className="font-medium">STOK</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStok15();
                                                       }}>
                                                        <i className="pi pi-history mr-2"></i>
                                                        <span className="font-medium">STOK15</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                                                        <i className="pi pi-info-circle mr-2"></i>
                                                        <span className="font-medium">STO8</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                                                        <i className="pi pi-paperclip mr-2"></i>
                                                        <span className="font-medium">STO9</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                                                        <i className="pi pi-history mr-2"></i>
                                                        <span className="font-medium">STOB</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                                                        <i className="pi pi-mobile mr-2"></i>
                                                        <span className="font-medium">STOW</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                            </ul>
                                        </li>


                                        <li className="lg:relative">
                                            <StyleClass nodeRef={btnRef5} selector="@next" enterClassName="hidden"
                                                        enterActiveClassName="scalein" leaveToClassName="hidden"
                                                        leaveActiveClassName="fadeout" hideOnOutsideClick>
                                                <a ref={btnRef5}
                                                   className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                                <i className="pi pi-eraser mr-2"></i>
                                                    <span>Sztornók</span>
                                                    <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>
                                            <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStovS();
                                                       }}>
                                                        <i className="pi pi-map-marker mr-2"></i>
                                                        <span className="font-medium">STOV_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStonS();
                                                       }}>
                                                        <i className="pi pi-info-circle mr-2"></i>
                                                        <span className="font-medium">STON_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>


                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showSto7S();
                                                       }}>
                                                        <i className="pi pi-mobile mr-2"></i>
                                                        <span className="font-medium">STO7_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>


                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showSto5S();
                                                       }}>
                                                        <i className="pi pi-globe mr-2"></i>
                                                        <span className="font-medium">STO5_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>


                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStobS();
                                                       }}>
                                                        <i className="pi pi-tablet mr-2"></i>
                                                        <span className="font-medium">STOB_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showSto0S();
                                                       }}>
                                                        <i className="pi pi-paperclip mr-2"></i>
                                                        <span className="font-medium">STO0_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>


                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStouS();
                                                       }}>
                                                        <i className="pi pi-paperclip mr-2"></i>
                                                        <span className="font-medium">STOU_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStoiS();
                                                       }}>
                                                        <i className="pi pi-history mr-2"></i>
                                                        <span className="font-medium">STOI_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStokS();
                                                       }}>
                                                        <i className="pi pi-history mr-2"></i>
                                                        <span className="font-medium">STOK_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                       onClick={() => {
                                                           showStok15S();
                                                       }}>
                                                        <i className="pi pi-history mr-2"></i>
                                                        <span className="font-medium">STOK15_S</span>
                                                        <Ripple/>
                                                    </a>
                                                </li>


                                            </ul>
                                        </li>
                                    </ul>

                                </div>

                            </div>
                            {/* <Panel 
                                className="pt-1 md:pt-1 lg:pt-1"
                                header="Műveletek">

                                <Menubar model={items} className="" />

                            </Panel> */}
                            <Panel /* headerTemplate={headerTemplate} */
                                className="pt-2 md:pt-2 lg:pt-2"
                                header="Szerződés alapadatai">
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                    <label
                                            htmlFor="contractNo"
                                            className="block text-900 font-medium mb-2">
                                            Szerződésszám
                                        </label>
                                        <InputText className="w-full"
                                            id="salesContractNo" value={salesContractDto.salesContractNo} />
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="contractValue"
                                            className="block text-900 font-medium mb-2">
                                            Vételár
                                        </label>
                                        <div className="p-inputgroup w-full">
                                            <InputNumber inputStyle={{ textAlign: 'left' }} value={salesContractDto.contractValue} id="contractValue" />
                                            <span className="p-inputgroup-addon">Ft</span>
                                        </div>
                                    </div>

                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="paymentPercentage"
                                            className="block text-900 font-medium mb-2">
                                            Befizetve
                                        </label>
                                        <div className="p-inputgroup w-full">
                                            <InputNumber inputStyle={{ textAlign: 'left' }} value={salesContractDto.paymentPercentage} id="paymentPercentage" />
                                            <span className="p-inputgroup-addon">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="contractNo"
                                            className="block text-900 font-medium mb-2">
                                            Szerződéskötés dátuma
                                        </label>
                                        <InputText className="w-full"
                                            id="salesContractNo" value={salesContractDto.orderDate} />
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="registrationDate"
                                            className="block text-900 font-medium mb-2">
                                            Rögzítés dátuma
                                        </label>
                                        <InputText className="w-full"
                                            id="registrationDate" value={salesContractDto.registrationDate} />
                                    </div>

                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="contractSigningDescription"
                                            className="block text-900 font-medium mb-2">
                                            Szerződéskötés helye
                                        </label>
                                        <InputText className="w-full"
                                            id="contractSigningDescription" value={salesContractDto.contractSigningDescription} />
                                    </div>

                                </div>


                                <Divider align="center">
                                    <span className="p-tag">Vevő adatok</span>
                                </Divider>


                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="sellToCustomerNo"
                                            className="block text-900 font-medium mb-2">
                                            Vevő száma
                                        </label>
                                        <InputText className="w-full" id="sellToCustomerNo"
                                            value={salesContractDto.sellToCustomerNo} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="sellToName"
                                            className="block text-900 font-medium mb-2">
                                            Vevő neve
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToName" value={salesContractDto.sellToName} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="sellToPhoneNo"
                                            className="block text-900 font-medium mb-2">
                                            Vevő telefonszáma
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToPhoneNo" value={salesContractDto.sellToPhoneNo} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="sellToPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Irányítószám
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToPostCode" value={salesContractDto.sellToPostCode} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="sellToCity"
                                            className="block text-900 font-medium mb-2">
                                            Város
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToCity" value={salesContractDto.sellToCity} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="sellToAddress"
                                            className="block text-900 font-medium mb-2">
                                            Cím
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToAddress" value={salesContractDto.sellToAddress} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="sellToEmailAddress"
                                            className="block text-900 font-medium mb-2">
                                            Email cím
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToEmailAddress" value={salesContractDto.sellToEmailAddress} />
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="contractNo"
                                            className="block text-900 font-medium mb-2">
                                            VIP száma
                                        </label>
                                        <InputText className="w-full"
                                            id="salesContractNo" value={salesContractDto.vipCardNo} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="vatRegistrationNo"
                                            className="block text-900 font-medium mb-2">
                                            Adószám
                                        </label>
                                        <InputText className="w-full"
                                            id="vatRegistrationNo" value={salesContractDto.vatRegistrationNo} />
                                    </div>

                                </div>



                                <Divider align="center">
                                    <span className="p-tag">Kereskedő adatok</span>
                                </Divider>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="representativeContractNo"
                                            className="block text-900 font-medium mb-2">
                                            Kereskedő RC száma
                                        </label>
                                        <InputText className="w-full" id="representativeExternalNo"
                                            value={salesContractDto.representativeContract.representativeContractNo} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="externalNo"
                                            className="block text-900 font-medium mb-2">
                                            Kereskedő száma
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToName" value={salesContractDto.representativeContract.externalNo} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="externalNo"
                                            className="block text-900 font-medium mb-2">
                                            Kereskedő neve
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToName" value={salesContractDto.representativeContract.name} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Irányítószám
                                        </label>
                                        <InputText className="w-full"
                                            id="billToPostCode" value={salesContractDto.representativeContract.postCode} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToCity"
                                            className="block text-900 font-medium mb-2">
                                            Város
                                        </label>
                                        <InputText className="w-full"
                                            id="billToCity" value={salesContractDto.representativeContract.city} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToAddress"
                                            className="block text-900 font-medium mb-2">
                                            Cím
                                        </label>
                                        <InputText className="w-full"
                                            id="billToAddress" value={salesContractDto.representativeContract.address} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Telefonszám
                                        </label>
                                        <InputText className="w-full"
                                            id="billToPostCode" value={salesContractDto.representativeContract.phoneNumber} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToCity"
                                            className="block text-900 font-medium mb-2">
                                            Mobilszám
                                        </label>
                                        <InputText className="w-full"
                                            id="billToCity" value={salesContractDto.representativeContract.mobilePhoneNumber} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToCity"
                                            className="block text-900 font-medium mb-2">
                                            Adószám
                                        </label>
                                        <InputText className="w-full"
                                            id="billToCity" value={salesContractDto.representativeContract.vatRegistrationNumber} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Email cím
                                        </label>
                                        <InputText className="w-full"
                                            id="billToPostCode" value={salesContractDto.representativeContract.email} />
                                    </div>

                                </div>




                                <Divider align="center">
                                    <span className="p-tag">Fizetés</span>
                                </Divider>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="paymentConditionCode"
                                            className="block text-900 font-medium mb-2">
                                            Fizetési feltétel kódja
                                        </label>
                                        <InputText className="w-full"
                                            id="salesContractNo" value={salesContractDto.paymentConditionCode} />
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="contractValue"
                                            className="block text-900 font-medium mb-2">
                                            Fizetési mód
                                        </label>
                                        <InputText className="w-full"
                                            id="salesContractNo" value={salesContractDto.paymentMethodCode} />
                                    </div>

                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="paymentPercentage"
                                            className="block text-900 font-medium mb-2">
                                            Részletek száma
                                        </label>
                                        <InputText className="w-full"
                                            id="salesContractNo" value={salesContractDto.noOfInstallments} />
                                    </div>
                                </div>

                                <Divider align="center">
                                    <span className="p-tag">Sztornó</span>
                                </Divider>


                                <div className="formgrid grid">
                                    <div className="field-checkbox col-12 md:col-4">
                                        <Checkbox inputId="binary" checked={canceled} />
                                        <label htmlFor="binary">Sztornózva</label>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="cancelDate"
                                            className="block text-900 font-medium mb-2">
                                            Stornó dátuma
                                        </label>
                                        <InputText className="w-full"
                                            id="cancelDate" value={salesContractDto.cancelDate} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="cancelCode"
                                            className="block text-900 font-medium mb-2">
                                            Sztornó kódja
                                        </label>
                                        <InputText className="w-full"
                                            id="cancelCode" value={salesContractDto.cancelCode} />
                                    </div>
                                </div>

                                <Divider align="center">
                                    <span className="p-tag">Számla</span>
                                </Divider>


                                <div className="formgrid grid">
                                    <div className="field-checkbox col-12 md:col-3">
                                        <Checkbox inputId="binary" checked={invoiced} />
                                        <label htmlFor="binary">Számla</label>
                                    </div>
                                    <div className="field-checkbox col-12 md:col-3">
                                        <Checkbox inputId="binary" checked={shipped} />
                                        <label htmlFor="binary">Szállítólevél</label>
                                    </div>
                                    <div className="field-checkbox col-12 md:col-3">
                                        <Checkbox inputId="binary" checked={completelyInvoiced} />
                                        <label htmlFor="binary">Számlázva</label>
                                    </div>
                                    <div className="field-checkbox col-12 md:col-3">
                                        <Checkbox inputId="binary" checked={completelyShipped} />
                                        <label htmlFor="binary">Kiszállítva</label>
                                    </div>
                                </div>




                            </Panel>

                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Egészségpénztár adatok">
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="hfCode"
                                            className="block text-900 font-medium mb-2">
                                            EP kódja
                                        </label>
                                        <InputText className="w-full"
                                            id="sellToPhoneNo" value={salesContractDto.hfCode} />
                                    </div>

                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="hfName"
                                            className="block text-900 font-medium mb-2">
                                            EP neve
                                        </label>
                                        <InputText className="w-full"
                                            id="hfName" value={salesContractDto.hfName} />
                                    </div>

                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="hfCustomerCode"
                                            className="block text-900 font-medium mb-2">
                                            EP Vevő kód
                                        </label>
                                        <InputText className="w-full"
                                            id="hfCustomerCode" value={salesContractDto.hfCustomerCode} />
                                    </div>

                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="hfPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Irányítószám
                                        </label>
                                        <InputText className="w-full"
                                            id="hfPostCode" value={salesContractDto.hfPostCode} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="hfCity"
                                            className="block text-900 font-medium mb-2">
                                            Város
                                        </label>
                                        <InputText className="w-full"
                                            id="hfCity" value={salesContractDto.hfCity} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="hfAddress"
                                            className="block text-900 font-medium mb-2">
                                            Cím
                                        </label>
                                        <InputText className="w-full"
                                            id="hfAddress" value={salesContractDto.hfAddress} />
                                    </div>
                                </div>

                            </Panel>

                            {/* <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Kereskedő adatok">


                            </Panel>
 */}


                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Befizetések">
                                <DataTable value={salesContractDto.contractLedgerEntryList} responsiveLayout="scroll"
                                    size="small"
                                    //rowGroupMode="subheader"
                                    //rowGroupFooterTemplate={contractLedgerEntryFooterTemplate}
                                    footer={contractLedgerEntryFooterTemplate}
                                >
                                    <Column alignHeader="left" field="vatRegistrationDocNo" header="Előlegszámla"></Column>
                                    <Column alignHeader="left" field="vatRegistrationDateTime" header="Előlegszámla dátuma"></Column>
                                    <Column alignHeader="left" field="postingDate" header="Dátum"></Column>
                                    <Column alignHeader="left" field="externalDocumentNo" header="Banki kivonat"></Column>
                                    <Column alignHeader="right" field="amount" header="Összeg" body={amountBodyTemplate} style={{ textAlign: 'right' }}></Column>
                                </DataTable>
                            </Panel>



                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Termékek">
                                <DataTable value={salesContractDto.salesContractLineList} responsiveLayout="scroll"
                                    size="small"
                                    rowGroupMode="subheader"
                                    rowGroupFooterTemplate={salesContractLineFooterTemplate}>
                                    <Column alignHeader="left" field="priceGroupCode" header="Árlista"></Column>
                                    <Column alignHeader="left" field="productCode" header="Termékkód"></Column>
                                    <Column alignHeader="left" field="description" header="Terméknév"></Column>
                                    <Column alignHeader="left" field="invoiceNo" header="Számlaszám"></Column>
                                    <Column alignHeader="right" style={{ textAlign: 'right' }} field="quantity" header="Mennyiség" body={quantityTemplate}></Column>
                                    <Column alignHeader="right" style={{ textAlign: 'right' }} field="beforeDiscountAmount" header="Ár" body={beforeDiscountAmountTemplate}></Column>
                                    <Column alignHeader="right" style={{ textAlign: 'right' }} field="afterDiscountAmount" header="Kedvezményes ár" body={afterDiscountAmountTemplate}></Column>
                                </DataTable>
                            </Panel>


                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Raktár">
                                <DataTable value={salesContractDto.warehouseItemList} responsiveLayout="scroll"
                                    size="small"
                                    rowGroupMode="subheader" groupRowsBy="salesItemNo" >
                                    <Column alignHeader="left" field="salesItemNo" header="Eladási termék kódja"></Column>
                                    <Column alignHeader="left" field="salesItemDescription" header="Eladási termék neve"></Column>
                                    <Column alignHeader="left" field="itemNo" header="Raktári termék kódja"></Column>
                                    <Column alignHeader="left" field="itemDescription" header="Raktári termék neve"></Column>
                                    <Column alignHeader="right" field="stock" header="Mennyiség" style={{ textAlign: 'right' }} body={stockTemplate}></Column>
                                    <Column alignHeader="right" field="reservedStock" header="Foglalt mennyiség" style={{ textAlign: 'right' }} body={reservedStockTemplate}></Column>
                                </DataTable>
                            </Panel>

                            {/* <Panel toggleable
                                collapsed={false}
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Számlázási adatok">
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToCustomerNo"
                                            className="block text-900 font-medium mb-2">
                                            Vevő száma
                                        </label>
                                        <InputText className="w-full"
                                            id="billToCustomerNo" value={salesContractDto.billToCustomerNo} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToName"
                                            className="block text-900 font-medium mb-2">
                                            Vevő neve
                                        </label>
                                        <InputText className="w-full"
                                            id="billToName" value={salesContractDto.billToName} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToPhoneNo"
                                            className="block text-900 font-medium mb-2">
                                            Vevő telefonszáma
                                        </label>
                                        <InputText className="w-full"
                                            id="billToPhoneNo" value={salesContractDto.billToPhoneNo} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Irányítószám
                                        </label>
                                        <InputText className="w-full"
                                            id="billToPostCode" value={salesContractDto.billToPostCode} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToCity"
                                            className="block text-900 font-medium mb-2">
                                            Város
                                        </label>
                                        <InputText className="w-full"
                                            id="billToCity" value={salesContractDto.billToCity} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="billToAddress"
                                            className="block text-900 font-medium mb-2">
                                            Cím
                                        </label>
                                        <InputText className="w-full"
                                            id="billToAddress" value={salesContractDto.billToAddress} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="vatRegistrationNo"
                                            className="block text-900 font-medium mb-2">
                                            Adószám
                                        </label>
                                        <InputText className="w-full"
                                            id="vatRegistrationNo" value={salesContractDto.vatRegistrationNo} />
                                    </div>

                                </div>
                            </Panel> */}


                            <Panel toggleable
                                collapsed={false}
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Szállítási adatok">
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="shipToName"
                                            className="block text-900 font-medium mb-2">
                                            Vevő neve
                                        </label>
                                        <InputText className="w-full"
                                            id="shipToName" value={salesContractDto.shipToName} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="shipToPhoneNo"
                                            className="block text-900 font-medium mb-2">
                                            Vevő telefonszáma
                                        </label>
                                        <InputText className="w-full"
                                            id="shipToPhoneNo" value={salesContractDto.shipToPhoneNo} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="shipToPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Irányítószám
                                        </label>
                                        <InputText className="w-full"
                                            id="shipToPostCode" value={salesContractDto.shipToPostCode} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="shipToCity"
                                            className="block text-900 font-medium mb-2">
                                            Város
                                        </label>
                                        <InputText className="w-full"
                                            id="shipToCity" value={salesContractDto.shipToCity} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="shipToAddress"
                                            className="block text-900 font-medium mb-2">
                                            Cím
                                        </label>
                                        <InputText className="w-full"
                                            id="shipToAddress" value={salesContractDto.shipToAddress} />
                                    </div>
                                </div>
                            </Panel>


                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Elsődleges kapcsolattartó">
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="primaryContactNo"
                                            className="block text-900 font-medium mb-2">
                                            CO szám
                                        </label>
                                        <InputText className="w-full"
                                            id="primaryContactNo" value={salesContractDto.primaryContactNo} />
                                    </div>

                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="primaryContactName"
                                            className="block text-900 font-medium mb-2">
                                            Név
                                        </label>
                                        <InputText className="w-full"
                                            id="hfNaprimaryContactNameme" value={salesContractDto.primaryContactName} />
                                    </div>



                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="primaryContactPostCode"
                                            className="block text-900 font-medium mb-2">
                                            Irányítószám
                                        </label>
                                        <InputText className="w-full"
                                            id="primaryContactPostCode" value={salesContractDto.primaryContactPostCode} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="primaryContactCity"
                                            className="block text-900 font-medium mb-2">
                                            Város
                                        </label>
                                        <InputText className="w-full"
                                            id="primaryContactCity" value={salesContractDto.primaryContactCity} />
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                        <label
                                            htmlFor="primaryContactAddress"
                                            className="block text-900 font-medium mb-2">
                                            Cím
                                        </label>
                                        <InputText className="w-full"
                                            id="primaryContactAddress" value={salesContractDto.primaryContactAddress} />
                                    </div>
                                </div>

                            </Panel>

                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Visszautalások">
                                <DataTable value={salesContractDto.transferBackList} responsiveLayout="scroll">
                                    <Column alignHeader="left" field="originalSalesContractNo" header="Szerződés szám"></Column>
                                    <Column alignHeader="left" field="salesContractNo" header="Visszautalva innen"></Column>
                                    <Column alignHeader="left" field="vatRegistrationDocNo" header="Előlegszámla"></Column>
                                    <Column alignHeader="left" field="vatRegistrationDateTime" header="Előlegszámla dátuma"></Column>
                                    <Column alignHeader="left" field="postingDate" header="Dátum"></Column>
                                    <Column alignHeader="right" field="amount" header="Összeg" body={amountBodyTemplate} style={{ textAlign: 'right' }}></Column>
                                    <Column alignHeader="left" field="externalDocumentNo" header="Banki kivonat"></Column>
                                </DataTable>
                            </Panel>

                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Számlák">
                                <DataTable value={salesContractDto.salesInvoiceLineList} responsiveLayout="scroll"
                                    size="small"
                                    rowGroupMode="subheader" groupRowsBy="documentNo" rowGroupFooterTemplate={salesInvoiceLineFooterTemplate}>
                                    <Column alignHeader="left" field="documentNo" header="Számla száma"></Column>
                                    <Column alignHeader="left" field="postingDate" header="Számla dátuma"></Column>
                                    <Column alignHeader="left" field="productCode" header="Raktári termék"></Column>
                                    <Column alignHeader="left" field="description" header="Leírás"></Column>
                                    <Column alignHeader="right" field="quantity" header="Mennyiség" style={{ textAlign: 'right' }} body={quantityTemplate}></Column>
                                    <Column alignHeader="right" field="unitPrice" header="Egységár" style={{ textAlign: 'right' }} body={unitPriceTemplate}></Column>
                                    <Column alignHeader="right" field="netAmount" header="Nettó ár" style={{ textAlign: 'right' }} body={netAmountTemplate}></Column>
                                    <Column alignHeader="right" field="vatPercentage" header="Áfa %" style={{ textAlign: 'right' }}></Column>
                                    <Column alignHeader="right" field="grossAmount" header="Bruttó ár" style={{ textAlign: 'right' }} body={grossAmountTemplate}></Column>
                                </DataTable>
                            </Panel>

                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Sztornó számlák">
                                <DataTable value={salesContractDto.salesCreditMemoLineList} responsiveLayout="scroll"
                                    size="small"
                                    rowGroupMode="subheader" groupRowsBy="documentNo" rowGroupFooterTemplate={salesCreditMemoLineFooterTemplate}>
                                    <Column alignHeader="left" field="documentNo" header="Sztornó számla száma"></Column>
                                    <Column alignHeader="left" field="invoiceNo" header="Számla száma"></Column>
                                    <Column alignHeader="left" field="postingDate" header="Sztornó számla dátuma"></Column>
                                    <Column alignHeader="left" field="productCode" header="Raktári termék"></Column>
                                    <Column alignHeader="left" field="description" header="Leírás"></Column>
                                    <Column alignHeader="right" field="quantity" header="Mennyiség" style={{ textAlign: 'right' }} body={quantityTemplate}></Column>
                                    <Column alignHeader="right" field="unitPrice" header="Egységár" style={{ textAlign: 'right' }} body={unitPriceTemplate}></Column>
                                    <Column alignHeader="right" field="netAmount" header="Nettó ár" style={{ textAlign: 'right' }} body={netAmountTemplate}></Column>
                                    <Column alignHeader="right" field="vatPercentage" header="Áfa %" style={{ textAlign: 'right' }}></Column>
                                    <Column alignHeader="right" field="grossAmount" header="Bruttó ár" style={{ textAlign: 'right' }} body={grossAmountTemplate}></Column>
                                </DataTable>
                            </Panel>
                            {/* <Panel
                            className="pt-3 md:pt-4 lg:pt-5"
                            header="Szállítólevelek">
                            <DataTable value={salesContractDto.salesShipmentHeaderList} responsiveLayout="scroll">
                                <Column alignHeader="left" field="shipmentNo" header="Szállítólevél száma"></Column>
                                <Column alignHeader="left" field="documentDate" header="Szállítólevél dátum"></Column>
                                <Column alignHeader="left" field="dueDate" header="Szállítási határidő"></Column>
                            </DataTable>
                        </Panel> */}
                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Szállítólevelek">
                                <DataTable value={salesContractDto.salesShipmentLineList} responsiveLayout="scroll"
                                    size="small"
                                    rowGroupMode="subheader" groupRowsBy="documentNo" >
                                    <Column alignHeader="left" field="documentNo" header="Szállítólevél száma"></Column>
                                    <Column alignHeader="left" field="shipmentDate" header="Szállítólevél dátuma"></Column>
                                    <Column alignHeader="left" field="productCode" header="Raktári termék"></Column>
                                    <Column alignHeader="left" field="description" header="Leírás"></Column>
                                    <Column alignHeader="right" field="quantity" header="Mennyiség" style={{ textAlign: 'right' }} body={quantityTemplate}></Column>
                                    <Column alignHeader="right" field="unitPrice" header="Egységár" style={{ textAlign: 'right' }} body={unitPriceTemplate}></Column>
                                    <Column alignHeader="right" field="lineAmount" header="Eladási ár" style={{ textAlign: 'right' }} body={lineAmountTemplate}></Column>
                                </DataTable>
                            </Panel>
                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Kommentek">
                                <DataTable value={salesContractDto.commentList} size="small" responsiveLayout="scroll">
                                    <Column alignHeader="left" field="source" header="Forrás"></Column>
                                    <Column alignHeader="left" field="commentDate" header="Dátum"></Column>
                                    <Column alignHeader="left" field="commentCode" header="Komment kód"></Column>
                                    <Column alignHeader="left" field="commentDescription" header="Komment név"></Column>
                                    <Column alignHeader="left" field="userDescription" header="Megjegyzés"></Column>
                                </DataTable>
                            </Panel>
                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Levelek">
                                <DataTable value={salesContractDto.letterList} size="small" responsiveLayout="scroll">
                                    <Column alignHeader="left" field="letterId" header="Levél azonosító"></Column>
                                    <Column alignHeader="left" field="created" header="Létrehozva"></Column>
                                    <Column alignHeader="left" field="downloaded" header="Letöltve"></Column>
                                    <Column alignHeader="left" field="downloadLink" header="Letöltés" body={downloadLinkTemplate}></Column>
                                </DataTable>
                            </Panel>

                            <Panel
                                className="pt-3 md:pt-4 lg:pt-5"
                                header="Bontások">
                                <DataTable value={salesContractDto.salesContractCancellationList} size="small" responsiveLayout="scroll">
                                    <Column alignHeader="left" field="letterId" header="Azonosító"></Column>
                                    <Column alignHeader="left" field="created" header="Létrehozva"></Column>
                                    <Column alignHeader="left" field="downloaded" header="Letöltve"></Column>
                                    {/*<Column alignHeader="left" field="downloadLink" header="Letöltés" body={cancellationDownloadLinkTemplate}></Column>*/}
                                    <Column
                                        alignHeader="left"
                                        field="downloadOriginalLink"
                                        header="Eredeti"
                                        style={{ flexGrow: 1, flexBasis: "70" }}
                                        body={cancellationDownloadOriginalLinkTemplate}
                                    ></Column>
                                    <Column
                                        alignHeader="left"
                                        field="downloadCopyLink"
                                        header="Másolat"
                                        style={{ flexGrow: 1, flexBasis: "70" }}
                                        body={cancellationDownloadCopyLinkTemplate}
                                    ></Column>
                                    <Column
                                        alignHeader="left"
                                        field="downloadCsvLink"
                                        header="Csekk"
                                        style={{ flexGrow: 1, flexBasis: "70" }}
                                        body={cancellationDownloadCsvLinkTemplate}
                                    ></Column>
                                </DataTable>
                            </Panel>
                        </>

                    ) : null}


                    <StovDialog
                        show={updateStovDialogShow}
                        change={updateStovDialogChange}
                        onClose={onStovDialogClose}
                    />

                    <StovSDialog
                        show={updateStovSDialogShow}
                        change={updateStovSDialogChange}
                        onClose={onStovSDialogClose}
                    />

                    <StonDialog
                        show={updateStonDialogShow}
                        change={updateStonDialogChange}
                        onClose={onStonDialogClose}
                    />

                    <StonSDialog
                        show={updateStonSDialogShow}
                        change={updateStonSDialogChange}
                        onClose={onStonSDialogClose}
                    />

                    <Sto7Dialog
                        show={updateSto7DialogShow}
                        change={updateSto7DialogChange}
                        onClose={onSto7DialogClose}
                    />

                    <StouDialog
                        show={updateStouDialogShow}
                        change={updateStouDialogChange}
                        onClose={onStouDialogClose}
                    />

                    <StoiDialog
                        show={updateStoiDialogShow}
                        change={updateStoiDialogChange}
                        onClose={onStoiDialogClose}
                    />

                    <StokDialog
                        show={updateStokDialogShow}
                        change={updateStokDialogChange}
                        onClose={onStokDialogClose}
                    />

                    <Stok15Dialog
                        show={updateStok15DialogShow}
                        change={updateStok15DialogChange}
                        onClose={onStok15DialogClose}
                    />


                    <Sto7SDialog
                        show={updateSto7SDialogShow}
                        change={updateSto7SDialogChange}
                        onClose={onSto7SDialogClose}
                    />

                    <Sto5SDialog
                        show={updateSto5SDialogShow}
                        change={updateSto5SDialogChange}
                        onClose={onSto5SDialogClose}
                    />

                    <StobSDialog
                        show={updateStobSDialogShow}
                        change={updateStobSDialogChange}
                        onClose={onStobSDialogClose}
                    />

                    <Sto0SDialog
                        show={updateSto0SDialogShow}
                        change={updateSto0SDialogChange}
                        onClose={onSto0SDialogClose}
                    />

                    <StouSDialog
                        show={updateStouSDialogShow}
                        change={updateStouSDialogChange}
                        onClose={onStouSDialogClose}
                    />

                    <StoiSDialog
                        show={updateStoiSDialogShow}
                        change={updateStoiSDialogChange}
                        onClose={onStoiSDialogClose}
                    />


                    <StokSDialog
                        show={updateStokSDialogShow}
                        change={updateStokSDialogChange}
                        onClose={onStokSDialogClose}
                    />

                    <Stok15SDialog
                        show={updateStok15SDialogShow}
                        change={updateStok15SDialogChange}
                        onClose={onStok15SDialogClose}
                    />

                    <F5Dialog
                        show={updateF5DialogShow}
                        change={updateF5DialogChange}
                        onClose={onF5DialogClose}
                    />

                </div>
            </BlockUI>
        </>
    );
};

export default SalesContract;
