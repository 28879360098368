import React from "react";
import cardBannerMYBNYJ from "../../asset/dashboard_card_banner_myb_nyj.png";


const Dashboard: React.FC = () => {

    return (
        <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
            <div className="surface-card border-round shadow-2 p-2 md:p-4 lg:p-5">
                <div className="text-center">
                    <img
                        src={cardBannerMYBNYJ}
                        height="150"
                        className="mb-1"
                        alt="Web Office Banner"
                    />
                    <div className="text-xl text-900 font-medium mb-3">
                        Üdvözöljük az Zepter vevőszolgálat rendszerében.
                    </div>
                    <p className="mt-0 mb-4 p-0 line-height-3">
                        Mai dátum: {new Date().toLocaleDateString()}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
