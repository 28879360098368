import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;




export const generateNyiLetter = () => {
    return axios
        .post(API_URL + '/protected/letter/nyi')
        .then((response) => {
            return response;
        });
};

export const generateNyi2Letter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/nyi2', request)
        .then((response) => {
            return response;
        });
};



export const readNyiLetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/nyi')
        .then((response) => {
            return response;
        });
};

export const readNyi2LetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/nyi2')
        .then((response) => {
            return response;
        });
};