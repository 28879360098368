import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generateStouLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/stou', request)
        .then((response) => {
            return response;
        });
};


export const generateStouSLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/stous', request)
        .then((response) => {
            return response;
        });
};
