import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { login, setAuthToken } from "../../service/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/SalesContract.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import { readSalesContract, searchSalesContract } from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import StatusCodes from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { cleanSalesContractSearch, setSearchFields, setSearchResult } from "../../state/salesContractSearchSlice";
import { generationF1ALetters, readF1ALetterGenerations } from "../../service/F1ALetterService";
import { Toast } from "primereact/toast";
import { readDM22LetterGenerations } from "../../service/DM22LetterService";
import { readDm22Statistics } from "../../service/StatisticsService";




const DM22Statistics: React.FC = () => {

    let navigate = useNavigate();
    setAuthToken();
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [dm22StatisticsList, setDm22StatisticsList] = useState([]);
    const [statisticsSummaryList, setStatisticsSummaryList] = useState([]);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const toast: any = useRef(null);
    const dt = useRef(null);

    const showInfo = (data: any) => {
        toast.current.show({ severity: 'info', summary: 'Info Message', detail: data.numberOfLetters + ' db F1A levél létrehozva', life: 3000 });
    }

    const excelDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.excelDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-excel mr-2 text-green-500"></i>
            <span className="font-medium">
                {rowData.excelFileName}
            </span>
        </a>
    }


    const wordDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.wordDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.wordFileName}
            </span>
        </a>
    }



    const saveAsExcelFile = (buffer: any, fileName: any) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const formatCurrency = (value: any) => {
        return value.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 });
    }


    const calculateSumAmount = (salesContractLineList: any) => {
        let total = 0;

        if (salesContractLineList) {
            for (let line of salesContractLineList) {
                total += line.resultAmount;
            }
        }
        return formatCurrency(total);
    }


    const salesContractLineFooterTemplate = () => {
        return (
            <React.Fragment>
                <td colSpan={2} style={{ textAlign: 'left' }}></td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Összesen</td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{calculateSumAmount(dm22StatisticsList)}</td>
            </React.Fragment>
        );
    }

    const formatNumber = (value: any) => {
        return value.toLocaleString('hu-HU', { style: 'decimal', maximumFractionDigits: 0 });
    }

    const numberTemplate = (rowData: any) => {
        return formatNumber(rowData.amount);
    }

    const amountTemplate = (rowData: any) => {
        return formatCurrency(rowData.amount);
    }

    const initialAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.initialAmount);
    }

    const resultAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.resultAmount);
    }

    useEffect(() => {
        setBlockedPage(true);
        readDm22Statistics().then(
            (response) => {
                console.log(response.data.letterGenerationList);
                setDm22StatisticsList(
                    response.data.dm22StatisticsLineList
                )
                setStatisticsSummaryList(
                    response.data.statisticsSummaryLineList
                )
                setBlockedPage(false);
            },
            (error) => {
                console.log(error.response.status);
                setBlockedPage(false);
            }
        );
    }, []);


    const formik = useFormik({
        initialValues: {
            fromDate: "",
            toDate: "",
        },
        validationSchema: Yup.object({
            fromDate: Yup.string()
                .required("Kötelező kitölteni!"),
            toDate: Yup.string()
                .required("Kötelező kitölteni!"),
        }),
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            setBlockedPage(true);

            generationF1ALetters(values).then(
                (response: any) => {
                    console.log(response);
                    setBlockedPage(false);
                    showInfo(response.data);
                },
                (error: any) => {
                    console.log(error.response);
                    setBlockedPage(false);
                    setMessageDialogHeader("Hiba");
                    setMessageDialogBody(
                        "Hiba a levelek létrehozása során!"
                    );
                    setMessageDialogError(true);
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(dm22StatisticsList);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'dm22_details');
        });
    }

    const exportExcelSummary = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(statisticsSummaryList);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'dm22_summary');
        });
    }

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" label="Excel export" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
        </div>
    );

    const headerSummary = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" label="Excel export" icon="pi pi-file-excel" onClick={exportExcelSummary} className="p-button-success mr-2" data-pr-tooltip="XLS" />
        </div>
    );

    return (
        <>
            <BlockUI
                blocked={blockedPage}
                fullScreen

                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        DM22 statisztika létrehozása folyamatban... Kérem várjon!
                    </div>
                }
            >
                <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">
                    <Toast ref={toast} />
                    <MessageDialog
                        messageDialogError={messageDialogError}
                        messageDialogShow={messageDialogShow}
                        messageDialogHeader={messageDialogHeader}
                        messageDialogBody={messageDialogBody}
                    />

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="DM22  statisztika összegzés">
                        <DataTable value={statisticsSummaryList} responsiveLayout="scroll" header={headerSummary}>
                            <Column alignHeader="left" field="description" header="Megjegyzés" sortable></Column>
                            <Column align="right" alignHeader="right" field="amount" header="Érték" sortable body={numberTemplate}></Column>
                        </DataTable>
                    </Panel>

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="DM22 statisztika részletek">
                        <DataTable value={dm22StatisticsList} responsiveLayout="scroll"
                            header={header}
                            rowGroupMode="subheader"
                            rowGroupFooterTemplate={salesContractLineFooterTemplate}>
                            <Column alignHeader="left" field="salesContractNo" header="Szerződés" sortable></Column>
                            <Column align="right" alignHeader="right" field="amount" header="Összeg" sortable body={amountTemplate}></Column>
                            <Column align="right" alignHeader="right" field="initialAmount" header="Kezdeti befizetés" body={initialAmountTemplate} sortable></Column>
                            <Column align="right" alignHeader="right" field="resultAmount" header="További befizetés" body={resultAmountTemplate} sortable ></Column>

                        </DataTable>
                    </Panel>

                </div>
            </BlockUI>
        </>
    );
};

export default DM22Statistics;
