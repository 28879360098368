import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const login = (userName: string, password: string) => {
    console.log(API_URL);
    return axios
        .post(API_URL + '/login', {
            userName,
            password,
        })
        .then((response) => {
            console.log(response.data.token);
            if (response.data.token) {
                sessionStorage.setItem(
                    'zcsUser',
                    JSON.stringify(response.data)
                );
                setAuthToken();
            }
            return response.data;
        });
};

export const logout = () => {
    sessionStorage.removeItem('zcsUser');
    setAuthToken();
};

export const getCurrentUser = () => {
    const userStr = sessionStorage.getItem('zcsUser');
    if (userStr) return JSON.parse(userStr);
    return null;
};

export const authHeader = () => {
    const userStr = sessionStorage.getItem('zcsUser');
    let user = null;
    if (userStr) user = JSON.parse(userStr);
    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }; // for Spring Boot back-end
    } else {
        return {};
    }
};

export const setAuthToken = () => {
    const userStr = sessionStorage.getItem('zcsUser');
    let user = null;
    if (userStr) user = JSON.parse(userStr);
    if (user && user.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    } else delete axios.defaults.headers.common['Authorization'];
};
