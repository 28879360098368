import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { login, setAuthToken } from "../../service/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/Admin.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import {
  readSalesContract,
  searchSalesContract,
} from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import StatusCodes from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import {
  cleanSalesContractSearch,
  setSearchFields,
  setSearchResult,
} from "../../state/salesContractSearchSlice";
import {
  generationF1ALetters,
  readF1ALetterGenerations,
} from "../../service/F1ALetterService";
import { Toast } from "primereact/toast";
import { readDM22LetterGenerations } from "../../service/DM22LetterService";
import {
  generationDM23aLetters,
  readDM23aLetterGenerations,
} from "../../service/DM23aLetterService";
import { confirmDialog } from "primereact/confirmdialog";
import {
  downloadSalesContractCancellation,
  readAllSalesContractCancellation,
  searchSalesContractCancellation
} from "../../service/SalesContractCancellationService";

const SalesContractCancellationList: React.FC = () => {
  let navigate = useNavigate();
  setAuthToken();
  const [blockedPage, setBlockedPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [salesContractCancellationList, setSalesContractCancellationList] =
    useState([]);
  const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
  const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
  const [messageDialogBody, setMessageDialogBody] = useState<string>("");
  const [messageDialogError, setMessageDialogError] = useState<boolean>(false);
  const toast: any = useRef(null);

  const letterTypes = [

    { label: "Összes", value: "" },
    { label: "STOV", value: "STOV" },
    { label: "STON", value: "STON" },
    { label: "STO5", value: "STO5" },
    { label: "STO7", value: "STO7" },
    { label: "STOB", value: "STOB" },
    { label: "STO0", value: "STO0" },
    { label: "STOU", value: "STOU" },
    { label: "STOI", value: "STOI" },
    { label: "STOK", value: "STOK" },
    { label: "STOK15", value: "STOK15" },
    { label: "STOV_S", value: "STOV_S" },
    { label: "STON_S", value: "STON_S" },
    { label: "STO5_S", value: "STO5_S" },
    { label: "STO7_S", value: "STO7_S" },
    { label: "STOB_S", value: "STOB_S" },
    { label: "STO0_S", value: "STO0_S" },
    { label: "STOU_S", value: "STOU_S" },
    { label: "STOI_S", value: "STOI_S" },
    { label: "STOK_S", value: "STOK_S" },
    { label: "STOK15_S", value: "STOK15_S" },
  ];

  const cancellationTypes = [

    { label: "Összes", value: "" },
    { label: "Kötbér nélküli bontás", value: "1" },
    { label: "Kötbéres bontás", value: "2" },
    { label: "Sztornók", value: "3" },
  ];

  const showInfo = (data: any) => {
    toast.current.show({
      severity: "info",
      summary: "Info Message",
      detail: data.numberOfLetters + " db F1A levél létrehozva",
      life: 3000,
    });
  };

  const cancellationDownloadOriginalLinkTemplate = (rowData: any) => {
    return (
      <a
        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
        style={{ borderRadius: "12px", textDecoration: "none" }}
        href={rowData.downloadOriginalLink}
        target="_blank"
        rel="noreferrer"
      >
        <i className="pi pi-file-word mr-2 text-indigo-500"></i>
      </a>
    );
  };

  const cancellationDownloadCsvLinkTemplate = (rowData: any) => {
    if (rowData.csvFileName === null) {
      return (<div style={{ width: "45px" }}></div>);
    } else {
      return (
        <a
          className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
          style={{ borderRadius: "12px", textDecoration: "none" }}
          href={rowData.downloadCsvLink}
          target="_blank"
          rel="noreferrer"
        >
          <i className="pi pi-file mr-2 text-green-500"></i>
        </a>
      );
    }
  };


  const cancellationDownloadCopyLinkTemplate = (rowData: any) => {
    return (
      <a
        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
        style={{ borderRadius: "12px", textDecoration: "none" }}
        href={rowData.downloadCopyLink}
        target="_blank"
        rel="noreferrer"
      >
        <i className="pi pi-file-word mr-2 text-red-500"></i>
      </a>
    );
  };

  const saveAsExcelFile = (buffer: any, fileName: any) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(salesContractCancellationList);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "salescontractcancellation");
    });
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        label="Excel export"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2"
        data-pr-tooltip="XLS"
      />
    </div>
  );

  const refresh = () => {
    setLoading(true);
    readAllSalesContractCancellation().then(
      (response) => {
        console.log(response.data.letterGenerationList);
        setSalesContractCancellationList(
          response.data.salesContractCancellationList
        );
        setLoading(false);
      },
      (error) => {
        console.log(error.response.status);
      }
    );
  };

  useEffect(() => {
    //refresh();
  }, []);

  const confirmSearch = () => {
    confirmDialog({
      message: "Valóban indítja a keresést?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        setLoading(true);
        searchSalesContractCancellation(formik.values).then(
          (response) => {
            setSalesContractCancellationList(
              response.data.salesContractCancellationList
            );
            setLoading(false);
          },
          (error) => {
            console.log(error.response.status);
          }
        );
      },
      reject: () => { },
    });
  };


  const confirmDownload = () => {
    confirmDialog({
      message: "Valóban indítja a letöltést?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        setLoading(true);
        downloadSalesContractCancellation(formik.values).then(
          (response) => {
            console.log(response);
            setBlockedPage(false);
            let blob = new Blob([response.data]);

            FileSaver(blob, "Bontások.xlsx");
            setLoading(false);
          },
          (error) => {
            console.log(error.response.status);
          }
        );
      },
      reject: () => { },
    });
  };

  const formik = useFormik({
    initialValues: {
      salesContractNo: "",
      fromDate: "",
      toDate: "",
      letterType: "",
      actionType: ""
    },
    validationSchema: Yup.object({

    }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      confirmSearch();
    },
  });

  const formatCurrency = (value: any) => {
    return value.toLocaleString("hu-HU", {
      style: "currency",
      currency: "HUF",
      maximumFractionDigits: 0,
    });
  };

  const creditGrossAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.creditGrossAmount);
  };

  const debitGrossAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.debitGrossAmount);
  };

  const bankDebitGrossAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.bankDebitGrossAmount);
  };

  const payedPenaltyAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.payedPenaltyGrossAmount);
  };

  const remainderPenaltyAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.remainderPenaltyAmount);
  };

  const penaltyGrossAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.penaltyGrossAmount);
  };

  const advancePaymentGrossAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.advancePaymentGrossAmount);
  };

  return (
    <>
      <BlockUI
        blocked={blockedPage}
        template={
          <div
            className="text-center text-teal-50"
            style={{ fontSize: "2rem" }}
          >
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }} />
            <br />
            Bontások betöltése folyamatban... Kérem várjon!
          </div>
        }
      >
        <div className="w-10 border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">
          <Toast ref={toast} />
          <MessageDialog
            messageDialogError={messageDialogError}
            messageDialogShow={messageDialogShow}
            messageDialogHeader={messageDialogHeader}
            messageDialogBody={messageDialogBody}
          />
          <Panel header="Bontások keresése" className="bg-blue-50">
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="formgrid grid">
                <div className="field col-12 md:col-2">
                  <label
                    htmlFor="salesContractNo"
                    className="block text-900 font-medium mb-2"
                  >
                    Szerződésszám
                  </label>

                  <InputText
                    className={
                      formik.errors.salesContractNo
                        ? "w-full p-invalid"
                        : "w-full"
                    }
                    id="salesContractNo"
                    {...formik.getFieldProps("salesContractNo")}
                  />
                </div>
                <div className="field col-12 md:col-2">
                  <label
                    htmlFor="fromDate"
                    className="block text-900 font-medium mb-2"
                  >
                    Dátumtól
                  </label>
                  <Calendar
                    className={
                      formik.errors.fromDate ? "w-full p-invalid" : "w-full"
                    }
                    showIcon
                    dateFormat="yy.mm.dd"
                    id="fromDate"
                    {...formik.getFieldProps("fromDate")}
                  />

                </div>

                <div className="field col-12 md:col-2">
                  <label
                    htmlFor="toDate"
                    className="block text-900 font-medium mb-2"
                  >
                    Dátumig
                  </label>
                  <Calendar
                    className={
                      formik.errors.toDate ? "w-full p-invalid" : "w-full"
                    }
                    showIcon
                    dateFormat="yy.mm.dd"
                    id="toDate"
                    {...formik.getFieldProps("toDate")}
                  />

                </div>

                <div className="field col-12 md:col-2">
                  <label
                    htmlFor="letterType"
                    className="block text-900 font-medium mb-2"
                  >
                    Levél típus
                  </label>
                  <Dropdown
                    id="letterType"
                    className="w-full mb-3"
                    {...formik.getFieldProps("letterType")}
                    options={letterTypes}
                    onChange={formik.handleChange}
                    value={formik.values.letterType}
                    placeholder="Válasszon levelet!"
                  />

                </div>

                <div className="field col-12 md:col-2">
                  <label
                    htmlFor="actionType"
                    className="block text-900 font-medium mb-2"
                  >
                    Bontási típus
                  </label>
                  <Dropdown
                    id="actionType"
                    className="w-full mb-3"
                    {...formik.getFieldProps("actionType")}
                    options={cancellationTypes}
                    onChange={formik.handleChange}
                    value={formik.values.actionType}
                    placeholder="Válasszon bontást!"
                  />

                </div>



              </div>

              <div className="formgrid grid">


              </div>

              <div className="formgrid grid">



              </div>

              <div className="formgrid grid">
                <div className="col-12 md:col-2">
                  {/*<Button icon="pi pi-search" label="Keresés" type="submit" />*/}
                  <Button
                    icon="pi pi-search"
                    label="Keresés"
                    className="p-button-outlined p-button-success"
                    onClick={() => {
                      confirmSearch();
                    }}
                    type="button"
                  />
                </div>

                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-download"
                    label="Letöltés"
                    className="p-button-outlined p-button-warning"
                    onClick={() => {
                      confirmDownload();
                    }}
                    type="button"
                  />
                </div>

                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-search-minus"
                    label="Új Keresés"
                    className="p-button-outlined p-button-help"
                    onClick={() => {
                      formik.resetForm({
                        values: {
                          salesContractNo: "",
                          fromDate: "",
                          toDate: "",
                          letterType: "",
                          actionType: ""
                        },
                        errors: {},
                      });
                    }}
                    type="button"
                  />
                </div>
              </div>
            </form>
          </Panel>

          <Panel className="pt-3 md:pt-4 lg:pt-5" header="Bontások">
            <DataTable
              //header={header}
              //className="admin-list"
              width="50vw"
              value={salesContractCancellationList}
              //responsiveLayout="scroll"
              scrollHeight="400px"
              loading={loading}
              scrollable
              size="small"
              //scrollDirection="both"
              paginator
              //currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
              rows={20}
              rowsPerPageOptions={[10, 20, 50, 100]}
            >
              <Column
                alignHeader="left"
                field="downloadOriginalLink"
                header="Eredeti"
                style={{ flexGrow: 1, flexBasis: "70" }}
                body={cancellationDownloadOriginalLinkTemplate}
              ></Column>
              <Column
                alignHeader="left"
                field="downloadCopyLink"
                header="Másolat"
                style={{ flexGrow: 1, flexBasis: "70" }}
                body={cancellationDownloadCopyLinkTemplate}
              ></Column>
              <Column
                alignHeader="left"
                field="downloadCsvLink"
                header="Csekk"
                style={{ flexGrow: 1, flexBasis: "70" }}
                body={cancellationDownloadCsvLinkTemplate}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="created"
                header="Létrehozva"
                style={{ flexGrow: 1, flexBasis: "180px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="salesContractNo"
                header="Szerződésszám"
                style={{ flexGrow: 1, flexBasis: "180px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="name"
                header="Vásárló"
                style={{ flexGrow: 1, flexBasis: "200px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="representativeExternalNo"
                header="Kereskedő száma"
                style={{ flexGrow: 1, flexBasis: "200px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="representativeName"
                header="Kereskedő neve"
                style={{ flexGrow: 1, flexBasis: "200px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="letterDate"
                header="Levél dátuma"
                style={{ flexGrow: 1, flexBasis: "150px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="postMarkDate"
                header="Postai bélyegző"
                style={{ flexGrow: 1, flexBasis: "150px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="letterType"
                header="Típus"
                style={{ flexGrow: 1, flexBasis: "100px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="registrationNo"
                header="Iktatószám"
                style={{ flexGrow: 1, flexBasis: "180px" }}
              ></Column>
              <Column
                sortable
                alignHeader="left"
                field="cancelledRegistrationNo"
                header="Sztornózott iktatószám"
                style={{ flexGrow: 1, flexBasis: "180px" }}
              ></Column>
              <Column
                alignHeader="right"
                align="right"
                field="advancePaymentGrossAmount"
                header="Befizetett összeg"
                style={{ flexGrow: 1, flexBasis: "150px" }}
                className="justify-content-end"
                body={advancePaymentGrossAmountTemplate}
              ></Column>
              <Column
                alignHeader="right"
                align="right"
                field="penaltyGrossAmount"
                header="Kötbér követelés"
                style={{ flexGrow: 1, flexBasis: "150px" }}
                className="justify-content-end"
                body={penaltyGrossAmountTemplate}
              ></Column>
              <Column
                alignHeader="right"
                align="right"
                field="payedPenaltyGrossAmount"
                header="Megfizetett kötbér"
                style={{ flexGrow: 1, flexBasis: "150px" }}
                className="justify-content-end"
                body={payedPenaltyAmountTemplate}
              ></Column>
                            <Column
                alignHeader="right"
                align="right"
                field="creditGrossAmount"
                header="Kötbér hátralék"
                style={{ flexGrow: 1, flexBasis: "150px" }}
                className="justify-content-end"
                body={creditGrossAmountTemplate}
              ></Column>

              <Column
                alignHeader="right"
                align="right"
                field="bankDebitGrossAmount"
                header="Banki jóváírás"
                style={{ flexGrow: 1, flexBasis: "150px" }}
                className="justify-content-end"
                body={bankDebitGrossAmountTemplate}
              ></Column>
              <Column
                alignHeader="right"
                align="right"
                field="debitGrossAmount"
                header="Jóváírás"
                style={{ flexGrow: 1, flexBasis: "150px" }}
                className="justify-content-end"
                body={debitGrossAmountTemplate}
              ></Column>



              {/* <Column
                alignHeader="right"
                align="right"
                field="remmainderPenaltyAmount"
                header="Kötbér hátralék"
                style={{ flexGrow: 1, flexBasis: "150px" }}
                className="justify-content-end"
                body={remainderPenaltyAmountTemplate}
              ></Column> */}

            </DataTable>
          </Panel>
        </div>
      </BlockUI>
    </>
  );
};

export default SalesContractCancellationList;
