import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generateStokLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/stok', request)
        .then((response) => {
            return response;
        });
};


export const generateStokSLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/stoks', request)
        .then((response) => {
            return response;
        });
};
