import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const searchOptIn = (request: any) => {
    console.log(request);
    
    return axios
        .post(API_URL + '/protected/optin/search', request)
        .then((response) => {
            return response;
        });
};

