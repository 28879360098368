import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { login, setAuthToken } from "../../service/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/SalesContract.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import { readSalesContract, searchSalesContract } from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import StatusCodes from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { cleanSalesContractSearch, setSearchFields, setSearchResult } from "../../state/salesContractSearchSlice";




const SearchSalesContract: React.FC = () => {
    const dispatch = useDispatch();

    const salesContractSearch: any = useSelector(
        (state: RootState) => state.salesContractSearch
    );

    let navigate = useNavigate();
    setAuthToken();
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [salesContractDraftList, setSalesContractDraftList] = useState([]);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const viewSalesContractTemplate = (rowData: any) => {
        return (
            <Button
                type="button"
                onClick={() => {
                    navigate("/main/salescontract?salesContractNo=" + rowData.salesContractNo);
                }}
                icon="pi pi-align-justify"
                className="p-button-rounded p-button-text"
                aria-label="Edit"
            />
        );
    };


    const formik = useFormik({
        initialValues: {
            salesContractNo: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.salesContractNo : "",
            vipNumber: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.vipNumber : "",
            name: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.name : "",
            zip: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.zip : "",
            email: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.email : "",
            telephone: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.telephone : "",
            city: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.city : "",
            street: salesContractSearch && salesContractSearch.searchFields ? salesContractSearch.searchFields.street : ""
        },
        validationSchema: Yup.object({
            salesContractNo: Yup.string(),
            vipNumber: Yup.string(),
            name: Yup.string(),
            zip: Yup.string(),
            city: Yup.string(),
            street: Yup.string(),
            email: Yup.string(),
            telephone: Yup.string(),
        }),
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            setBlockedPage(true);
            salesContractDraftList.slice(0);

            dispatch(setSearchFields(values));


            searchSalesContract(values).then(
                (response: any) => {
                    console.log(response);
                    setBlockedPage(false);
                    setSalesContractDraftList(response.data.salesContractDraftList);
                    dispatch(setSearchResult(response.data.salesContractDraftList));
                },
                (error: any) => {
                    console.log(error.response);
                    setBlockedPage(false);
                    setMessageDialogHeader("Hiba a keresés során");
                    setMessageDialogBody(
                        "Nincs találat!"
                    );
                    setMessageDialogError(true);
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });



    return (
        <>
            <BlockUI
                blocked={blockedPage}
                fullScreen
                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        Szerződések keresése folyamatban. Kérem várjon!
                    </div>
                }
            />
            <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">
                <MessageDialog
                    messageDialogError={messageDialogError}
                    messageDialogShow={messageDialogShow}
                    messageDialogHeader={messageDialogHeader}
                    messageDialogBody={messageDialogBody}
                />
                <Panel header="Szerződés keresése" className="bg-blue-50">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">


                        <div className="formgrid grid">
                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="salesContractNo"
                                    className="block text-900 font-medium mb-2" >
                                    Szerződésszám
                                </label>

                                <InputText
                                    className={
                                        formik.errors.salesContractNo
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="salesContractNo"  {...formik.getFieldProps("salesContractNo")} />

                                {formik.errors.salesContractNo && formik.touched.salesContractNo ? (
                                    <small className="p-error">
                                        {formik.errors.salesContractNo.toString()}
                                    </small>
                                ) : null}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="contractNo"
                                    className="block text-900 font-medium mb-2"
                                >
                                    VIP száma
                                </label>

                                <InputText
                                    className={
                                        formik.errors.vipNumber
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="salesContractNo"  {...formik.getFieldProps("vipNumber")} />

                                {formik.errors.vipNumber ? (
                                    <small className="p-error">
                                        {formik.errors.vipNumber.toString()}
                                    </small>
                                ) : null}
                            </div>


                        </div>

                        <div className="formgrid grid">

                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="contractNo"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Vevő neve
                                </label>

                                <InputText
                                    className={
                                        formik.errors.name
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="salesContractNo"  {...formik.getFieldProps("name")} />

                                {formik.errors.name ? (
                                    <small className="p-error">
                                        {formik.errors.name.toString()}
                                    </small>
                                ) : null}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="zip"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Irányítószám
                                </label>

                                <InputText
                                    className={
                                        formik.errors.zip
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="salesContractNo"  {...formik.getFieldProps("zip")} />

                                {formik.errors.zip ? (
                                    <small className="p-error">
                                        {formik.errors.zip.toString()}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="formgrid grid">

                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="city"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Város
                                </label>

                                <InputText
                                    className={
                                        formik.errors.city
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="city"  {...formik.getFieldProps("city")} />

                                {formik.errors.city ? (
                                    <small className="p-error">
                                        {formik.errors.city.toString()}
                                    </small>
                                ) : null}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="street"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Cím
                                </label>

                                <InputText
                                    className={
                                        formik.errors.street
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="street"  {...formik.getFieldProps("street")} />

                                {formik.errors.street ? (
                                    <small className="p-error">
                                        {formik.errors.street.toString()}
                                    </small>
                                ) : null}
                            </div>
                        </div>


                        <div className="formgrid grid">

                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="email"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Email
                                </label>

                                <InputText
                                    className={
                                        formik.errors.email
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="email"  {...formik.getFieldProps("email")} />

                                {formik.errors.email ? (
                                    <small className="p-error">
                                        {formik.errors.email.toString()}
                                    </small>
                                ) : null}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label
                                    htmlFor="telephone"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Telefon
                                </label>

                                <InputText
                                    className={
                                        formik.errors.telephone
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="telephone"  {...formik.getFieldProps("telephone")} />

                                {formik.errors.telephone ? (
                                    <small className="p-error">
                                        {formik.errors.telephone.toString()}
                                    </small>
                                ) : null}
                            </div>
                        </div>


                        <div className="formgrid grid">

                            <div className="col-12 md:col-6">
                                <Button
                                    icon="pi pi-search"
                                    outlined
                                    label="Keresés"
                                    type="submit"
                                />
                            </div>

                            <div className="field col-12 md:col-6">
                            <Button
                                icon="pi pi-search-minus"
                                label="Új Keresés"
                                className="p-button-warning"
                                outlined
                                onClick={() => {
                                    formik.resetForm({
                                        values: {
                                            salesContractNo: "",
                                            vipNumber: "",
                                            zip: "",
                                            city: "",
                                            street: "",
                                            telephone: "",
                                            email: "",
                                            name: ""
                                        },
                                        errors: {},
                                    });
                                    dispatch(cleanSalesContractSearch());
                                }}
                                type="button"
                            />
                        </div>

                        </div>
                    </form>
                </Panel>

                {salesContractSearch && salesContractSearch.salesContractDraftList.length !== 0 ? (
                    <>

                        <Panel
                            className="pt-3 md:pt-4 lg:pt-5"
                            header="Szerződések">
                            <DataTable value={salesContractSearch.salesContractDraftList} responsiveLayout="scroll">
                                <Column alignHeader="left" field="salesContractNo" header="Szerződésszám" sortable filter></Column>
                                <Column alignHeader="left" field="orderDate" header="Szerződés dátuma" sortable filter></Column>
                                <Column alignHeader="left" field="vipCardNo" header="VIP szám"></Column>
                                <Column alignHeader="left" field="sellToName" header="Vásárló" sortable filter></Column>
                                <Column alignHeader="left" field="address" header="Cím"></Column>
                                <Column
                                    header="Megtekintés"
                                    body={viewSalesContractTemplate}
                                />
                            </DataTable>
                        </Panel>
                    </>) : null}

            </div>
        </>
    );
};

export default SearchSalesContract;
