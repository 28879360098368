import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generationF1ALetters = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/f1a', request)
        .then((response) => {
            return response;
        });
};


export const readF1ALetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/f1a')
        .then((response) => {
            return response;
        });
};

