import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";




const F5Dialog: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        dialogShow,
        setDialogShow,
    ] = useState<boolean>(false);

    const [
        dialogChange,
        setDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setDialogShow(false);
        formik.resetForm();
    };


    const projectId: string = props.projectId;
    const onClose = props.onClose;

    const onCloseModal = (values: any) => {
        onClose(values);
    }

    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban menti a módosításokat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                //values = {taskId: taskId, comment: values.coment};
                hideDialog();
                //alert(JSON.stringify(values, null, 2));
                onCloseModal(values.numberOfYellowChecks);
            },
            reject: () => { }
        });
    }

    useEffect(() => {
        if (
            props.show === true
        ) {
            setDialogChange(props.change);
            setDialogShow(props.show);
        }
    }, [dialogChange, navigate, props.show, props.change, projectId]);

    const formik = useFormik({
        initialValues: {
            numberOfYellowChecks: 0
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            numberOfYellowChecks: Yup.number()
                .required("Kötelező kitölteni!")
        }),
        onSubmit: (values) => {

            if (dialogShow) {

                confirmSave(values);
            }
        },
    });


    return (
        <>
            <Dialog header="F5 levél létrehozása" visible={dialogShow} closable={false}
                maximizable modal style={{ width: "70vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="numberOfYellowChecks"
                                className="block text-900 font-medium mb-2"
                            >
                                Csekkek száma
                            </label>

                            <InputNumber id="numberOfYellowChecks"  inputId="numberOfYellowChecks"  showButtons
                             className={
                                formik.errors.numberOfYellowChecks
                                    ? "w-full p-invalid"
                                    : "w-full"
                            }
                                value={formik.values.numberOfYellowChecks}  onChange={v => {
                                    formik.setFieldValue("numberOfYellowChecks", v.value);
                                  }}
                               // {...formik.getFieldProps("numberOfYellowChecks")} 
                                mode="decimal" min={0} max={12} />

                            {formik.errors.numberOfYellowChecks ? (
                                <small className="p-error">
                                    {formik.errors.numberOfYellowChecks}
                                </small>
                            ) : null}
                        </div>


                    </div>

                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default F5Dialog;





