import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generateF5Letter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/f5', request)
        .then((response) => {
            return response;
        });
};


export const readF5LetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/f5')
        .then((response) => {
            return response;
        });
};