import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generationDM23bLetters = () => {
    return axios
        .post(API_URL + '/protected/letter/dm23b')
        .then((response) => {
            return response;
        });
};


export const readDM23bLetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/dm23b')
        .then((response) => {
            return response;
        });
};

