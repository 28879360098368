import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generateV2Letter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/v2', request)
        .then((response) => {
            return response;
        });
};


export const generateV1Letter = () => {
    return axios
        .post(API_URL + '/protected/letter/v1')
        .then((response) => {
            return response;
        });
};



export const readV2LetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/v2')
        .then((response) => {
            return response;
        });
};



export const readV1LetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/v1')
        .then((response) => {
            return response;
        });
};