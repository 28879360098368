import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generateStok15Letter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/stok15', request)
        .then((response) => {
            return response;
        });
};


export const generateStok15SLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/stok15s', request)
        .then((response) => {
            return response;
        });
};
