import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { login, setAuthToken } from "../../service/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/SalesContract.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import { readSalesContract, searchSalesContract } from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import StatusCodes from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { cleanSalesContractSearch, setSearchFields, setSearchResult } from "../../state/salesContractSearchSlice";
import { generationF1ALetters, readF1ALetterGenerations } from "../../service/F1ALetterService";
import { Toast } from "primereact/toast";
import { readDM22LetterGenerations } from "../../service/DM22LetterService";
import {readDm22Statistics, readF1AStatistics, readV1Statistics} from "../../service/StatisticsService";
import { confirmDialog } from "primereact/confirmdialog";
import { checkPrime } from "crypto";
import {readV1LetterGenerations} from "../../service/FinalLetterService";




const V1Statistics: React.FC = () => {

    let navigate = useNavigate();
    setAuthToken();
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [statisticsList, setStatisticsList] = useState([]);
    const [statisticsSummaryList, setStatisticsSummaryList] = useState([]);
    const [letterGenerationList, setLetterGenerationList] = useState([]);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const toast: any = useRef(null);
    const dt = useRef(null);

    const showInfo = (data: any) => {
        toast.current.show({ severity: 'info', summary: 'Info Message', detail: data.numberOfLetters + ' db F1A levél létrehozva', life: 3000 });
    }

    const v1StatisticsTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer"
            style={{ borderRadius: "12px",  width: '27px',  }}
            onClick={() => {
                createV1Statistics(rowData);
            }}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-cog mr-2 text-green-500"></i>
        </a>
    }


    const wordDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.wordDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.wordFileName}
            </span>
        </a>
    }



    const saveAsExcelFile = (buffer: any, fileName: any) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const formatCurrency = (value: any) => {
        return value.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 });
    }


    const calculateSumAmount = (salesContractLineList: any) => {
        let total = 0;

        if (salesContractLineList) {
            for (let line of salesContractLineList) {
                total += line.resultAmount;
            }
        }
        return formatCurrency(total);
    }


    const salesContractLineFooterTemplate = () => {
        return (
            <React.Fragment>
                <td colSpan={2} style={{ textAlign: 'left' }}></td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Összesen</td>
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{calculateSumAmount(statisticsList)}</td>
                <td colSpan={2} style={{ textAlign: 'left' }}></td>
            </React.Fragment>
        );
    }

    const formatNumber = (value: any) => {
        return value.toLocaleString('hu-HU', { style: 'decimal', maximumFractionDigits: 0 });
    }

    const numberTemplate = (rowData: any) => {
        return formatNumber(rowData.amount);
    }

    const amountTemplate = (rowData: any) => {
        return formatCurrency(rowData.amount);
    }

    const initialAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.initialAmount);
    }

    const resultAmountTemplate = (rowData: any) => {
        return formatCurrency(rowData.resultAmount);
    }

    const invoicedBodyTemplate = (rowData: any) => {
        return <Checkbox checked={rowData.invoiced} readOnly />;
    }

    const canceledBodyTemplate = (rowData: any) => {
        return <Checkbox checked={rowData.canceled} readOnly />;
    }


    const createV1Statistics = (rowData: any) => {
        confirmDialog({
            message: 'Valóban létrehozza az V1 statisztikát?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                setBlockedPage(true);
                readV1Statistics(rowData.id).then(
                    (response) => {
                        setStatisticsList(
                            response.data.statisticsLineList
                        )
                        setStatisticsSummaryList(
                            response.data.statisticsSummaryLineList
                        )
                        setBlockedPage(false);
                    },
                    (error) => {
                        console.log(error);
                        setBlockedPage(false);
                    }
                );
                
            },
            reject: () => { }
        });
    };


    useEffect(() => {
        setLoading(true);
        readV1LetterGenerations().then(
            (response) => {
                setLoading(false);
                setStatisticsList([]);
                setStatisticsSummaryList([]);
                setLetterGenerationList(
                    response.data.letterGenerationList
                )
                //setBlockedPage(false);
            },
            (error) => {
                console.log(error.response.status);
                setLoading(false);
            }
        );

    }, []);


    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(statisticsList);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'f1a_details');
        });
    }

    const exportExcelSummary = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(statisticsSummaryList);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'f1a_summary');
        });
    }

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" label="Excel export" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-rounded p-button-text" data-pr-tooltip="XLS" />
        </div>
    );

    const headerSummary = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" label="Excel export" icon="pi pi-file-excel" onClick={exportExcelSummary} className="p-button-rounded p-button-text" data-pr-tooltip="XLS" />
        </div>
    );

    return (
        <>
            <BlockUI
                blocked={blockedPage}
                fullScreen

                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        V1 statisztika létrehozása folyamatban... Kérem várjon!
                    </div>
                }
            >
                <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">
                    <Toast ref={toast} />
                    <MessageDialog
                        messageDialogError={messageDialogError}
                        messageDialogShow={messageDialogShow}
                        messageDialogHeader={messageDialogHeader}
                        messageDialogBody={messageDialogBody}
                    />

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="V1 levél létrehozások">
                        <DataTable value={letterGenerationList} responsiveLayout="scroll" loading={loading}>
                            <Column alignHeader="left" field="excelDownloadLink" header="Statisztika" body={v1StatisticsTemplate}></Column>
                            <Column alignHeader="left" field="commentDate" header="Komment dátum" sortable></Column>
                            <Column alignHeader="left" field="fromDate" header="Dátumtól" sortable></Column>
                            <Column alignHeader="left" field="toDate" header="Dátumig" sortable></Column>
                            <Column alignHeader="left" field="numberOfLetters" header="Levelek száma"></Column>
                        </DataTable>
                    </Panel>

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="V1  statisztika összegzés">
                        <DataTable value={statisticsSummaryList} responsiveLayout="scroll" header={headerSummary}>
                            <Column alignHeader="left" field="description" header="Megjegyzés" sortable></Column>
                            <Column align="right" alignHeader="right" field="amount" header="Érték" sortable body={numberTemplate}></Column>
                        </DataTable>
                    </Panel>

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="V1 statisztika részletek">
                        <DataTable value={statisticsList} responsiveLayout="scroll"
                            header={header}
                            rowGroupMode="subheader"
                            rowGroupFooterTemplate={salesContractLineFooterTemplate}>
                            <Column alignHeader="left" field="salesContractNo" header="Szerződés" sortable></Column>
                            <Column align="right" alignHeader="right" field="amount" header="Összeg" sortable body={amountTemplate}></Column>
                            <Column align="right" alignHeader="right" field="initialAmount" header="Kezdeti befizetés" body={initialAmountTemplate} sortable></Column>
                            <Column align="right" alignHeader="right" field="resultAmount" header="További befizetés" body={resultAmountTemplate} sortable ></Column>
                            <Column align="center" alignHeader="right" field="invoiced" header="Számlázva" body={invoicedBodyTemplate} sortable ></Column>
                            <Column align="center" alignHeader="right" field="canceled" header="Sztornózva" body={canceledBodyTemplate} sortable ></Column>

                        </DataTable>
                    </Panel>

                </div>
            </BlockUI>
        </>
    );
};

export default V1Statistics;
