import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const generateSto0Letter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/sto0', request)
        .then((response) => {
            return response;
        });
};

export const generateSto0SLetter = (request: any) => {
    return axios
        .post(API_URL + '/protected/letter/sto0s', request)
        .then((response) => {
            return response;
        });
};
